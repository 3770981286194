<template>
  <div
    class="picks-nav fsp-element"
  >
    <ul
      class="picks-nav__list"
      role="tablist"
    >
      <li
        v-for="(item, index) in navData"
        :key="index"
        class="picks-nav__item"
        role="option"
      >
        <a
          tabindex="0"
          :class="{ 'picks-nav__link_active': +item.id === +catInfo.picks_id }"
          class="picks-nav__link"
          role="tab"
          :aria-checked="+item.id === +catInfo.picks_id"
          @click="getPicksUrl(item)"
        >
          {{ item.title }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { getQueryString, stringifyQueryString } from '@shein/common-function'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'PicksNav',
  props: {
    navData: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    ...mapState(['PICKS_LINKS', 'IS_RW']),
    ...mapGetters(['catInfo']),
  },
  methods: {
    getPicksUrl(item) {
      const href = `/${
        this.PICKS_LINKS[this.catInfo.picksType] ||
        (this.IS_RW ? 'promo-discount' : 'shein-picks')
      }-list-${item.id}.html`
      const params = {}
      const saveKeys = [
        'ici',
        'cacheKey',
        'src_module',
        'src_tab_page_id',
        'src_identifier',
        'styleType',
      ]
      saveKeys.forEach(
        (key) => this.catInfo[key] && (params[key] = this.catInfo[key])
      )

      if (this.catInfo.srctype && this.catInfo.userpath && typeof this.catInfo.userpath === 'string') {
        params.srctype = this.catInfo.srctype
        params.userpath = this.catInfo.userpath.replace(/&gt;/g, '>')
      }

      const mallCode = getQueryString({ key: 'mallCode' })
      mallCode && (params.mallCode = mallCode)
      const str = stringifyQueryString({ queryObj: params })
      location.href = str ? `${href}?${str}` : href
    },
  },
}
</script>

<style lang="less">
.product-list-v2 {
  .picks-nav {
    margin: 15px -8px 5px;
    &__list {
      display: flex;
      flex-wrap: wrap;
    }
    &__item {
      margin-bottom: 15px;
      padding: 0 8px;
      width: 16.66%;
      height: 40px;
      font-size: 14px;
    }
    &__link {
      display: block;
      padding: 0 20px;
      height: 40px;
      line-height: 40px;
      color: #000;
      border: 1px solid #e5e5e5;
      transition: 0.3s border ease-in-out, 0.3s background-color ease-in-out;
      text-decoration: none;
      text-align: center;
      .text-overflow();
      /*rw:begin*/
      border-radius: 2px;
      &:hover {
        border-color: #222;
        /*rw:begin*/
        color: #ff696e;
        border-color: #ff696e;
      }
    }
    &__link_active {
      /* 选中样式 */
      color: #fff;
      background-color: @sui_color_main;
      border-color: @sui_color_main;
      &:hover {
        /*sh:begin*/
        background-color: rgba(34, 34, 34, 0.8);
        border-color: transparent;
        /*rw:begin*/
        color: #fff;
        background-color: rgba(255, 105, 110, 0.8);
        border-color: transparent;
      }
    }
  }
}
</style>
