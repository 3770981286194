export const searchResultStoreHeaderData = {
  storeCode: 6596207479,
  title: 'allpairs',
  logo: 'http://img.ltwebstatic.com/images3_srm/2024/01/04/26/1704362935ad01787d8a28e812634e359bced25272.jpg',
  descriptions: 'Our store aims to create the most covetable, comfortable, fashionable and stylish footwear designs. \nOur New York based teams are uttermost sensitive about latest trend of color, material and style.  We carry a complete line of footwear for the whole family, such as Sandals, Pumps, Flats, Boots, Athletic shoes, Slippers, Sneakers, Men oxfords, Loafers, Slip-Ons, running shoes, Rain footwear etc. With over a decade of experience, we are the expert in the footwear business.',
  storeStatus: 1,
  storeType: 1,
  storeStoreType: 1,
  salesArea: 1,
  stockMode: 4,
  tocPerformParty: 2,
  sellerId: 2264411,
  sellerSource: 10,
  storeBusinessType: 1,
  subjectId: null,
  quantity: 0,
  labels: [
  ],
  followsCount: '4.5K',
  followsStatus: undefined,
  score: '4.90',
  storeServicePolicyLabel: [
  ],
  storeTags: [
    {
      searchKey: '6596207479',
      machineLabel: {
      },
      labelCloud: null,
    },
  ],
  products: [
    {
      relatedColor: '',
      cacheDataV6: '31593707@1',
      goods_id: 31593707,
      goods_sn: 'sx2302137722879415',
      goods_name: 'Women Cowboy Boots Mid Calf Cowgirl Boots Embroidery Stitched Square Toe Valentine\'s Day Western Boots',
      goods_url_name: 'Women Cowboy Boots Mid Calf Cowgirl Boots Embroidery Stitched Square Toe Valentine s Day Western Boots',
      cat_id: 6881,
      goods_img: '//img.ltwebstatic.com/images3_spmp/2024/03/15/31/1710493323badfaa1b9ce66656ecb1ee6ee739901d_thumbnail_405x552.jpg',
      is_on_sale: 1,
      original_img: '//img.ltwebstatic.com/images3_spmp/2024/03/15/31/1710493323badfaa1b9ce66656ecb1ee6ee739901d.jpg',
      goods_color_img: '//img.ltwebstatic.com/images3_spmp/2024/03/15/d0/1710493640cd386c33c664b24a08e6434730cf3503.jpg',
      supplier_id: 2264411,
      productRelationID: 'x23021377228',
      goods_img_webp: '//img.ltwebstatic.com/images3_spmp/2024/03/15/31/1710493323badfaa1b9ce66656ecb1ee6ee739901d_thumbnail_405x552.webp',
      original_img_webp: '//img.ltwebstatic.com/images3_spmp/2024/03/15/31/1710493323badfaa1b9ce66656ecb1ee6ee739901d.webp',
      is_pre_sale_end: 0,
      business_model: 1,
      store_code: 6596207479,
      mall_code: '1',
      ext: {
        rec_mark: 'mmd_12498|bkt|rkt|loc_0_0',
      },
      mallList: [
        {
          mall_code: '1',
          stock: 999,
          mall_code_sort: 1,
          sku_quick_ship: true,
        },
      ],
      retailPrice: {
        amount: '57.99',
        amountWithSymbol: '$57.99',
        usdAmount: '57.99',
        usdAmountWithSymbol: '$57.99',
        amountSimpleText: '57.99',
      },
      salePrice: {
        amount: '27.99',
        amountWithSymbol: '$27.99',
        usdAmount: '27.99',
        usdAmountWithSymbol: '$27.99',
        amountSimpleText: '27.99',
      },
      promotionInfo: [
      ],
      appPromotion: [
      ],
      discountPrice: {
        amount: '30.00',
        amountWithSymbol: '$30.00',
        usdAmount: '30.00',
        usdAmountWithSymbol: '$30.00',
        amountSimpleText: '30.00',
      },
      unit_discount: 52,
      original_discount: '52',
      mall_price: [
        {
          mall_code: '1',
          retailPrice: {
            amount: '57.99',
            amountWithSymbol: '$57.99',
            usdAmount: '57.99',
            usdAmountWithSymbol: '$57.99',
            amountSimpleText: '57.99',
          },
          salePrice: {
            amount: '27.99',
            amountWithSymbol: '$27.99',
            usdAmount: '27.99',
            usdAmountWithSymbol: '$27.99',
            amountSimpleText: '27.99',
          },
          discountPrice: {
            amount: '30.00',
            amountWithSymbol: '$30.00',
            usdAmount: '30.00',
            usdAmountWithSymbol: '$30.00',
            amountSimpleText: '30.00',
          },
          unit_discount: 52,
          promotion_status: null,
          promotion_logo_type: null,
          original_discount: '52',
          coupon_prices: [
          ],
        },
      ],
      mallAppPromotion: [
      ],
      mallPromotionInfo: [
      ],
      promotion_status: null,
      promotion_logo_type: null,
      coupon_prices: [
      ],
      showPrice: null,
      showTypeId: null,
      vipDiscountPrice: null,
      suitRulePrice: null,
      rule_num: null,
      discountValue: null,
      isInsurable: 0,
      promotion_discount_info: [
      ],
      coupons: [
      ],
      skc: 'sx2302137722879415',
      brand: null,
      series: null,
      series_list: [
      ],
      _series: {
      },
      _brand: {
      },
      productDetails: [
      ],
      comment: {
        comment_num: '0',
        comment_rank: '0',
      },
      sale_attr_list: {
        goods_id: '31593707',
        goods_sn: 'sx2302137722879415',
        skc_name: 'sx2302137722879415',
        sku_list: [
          {
            sku_code: 'I035dgpnknpe',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 717,
                attr_value_name: 'US6',
                attr_value_name_en: 'US6',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '57.99',
                amountWithSymbol: '$57.99',
                usdAmount: '57.99',
                usdAmountWithSymbol: '$57.99',
                amountSimpleText: '57.99',
              },
              salePrice: {
                amount: '27.99',
                amountWithSymbol: '$27.99',
                usdAmount: '27.99',
                usdAmountWithSymbol: '$27.99',
                amountSimpleText: '27.99',
              },
              discountPrice: {
                amount: '30.00',
                amountWithSymbol: '$30.00',
                usdAmount: '30.00',
                usdAmountWithSymbol: '$30.00',
                amountSimpleText: '30.00',
              },
              unit_discount: 52,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 27,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '57.99',
                  amountWithSymbol: '$57.99',
                  usdAmount: '57.99',
                  usdAmountWithSymbol: '$57.99',
                  amountSimpleText: '57.99',
                },
                salePrice: {
                  amount: '27.99',
                  amountWithSymbol: '$27.99',
                  usdAmount: '27.99',
                  usdAmountWithSymbol: '$27.99',
                  amountSimpleText: '27.99',
                },
                discountPrice: {
                  amount: '30.00',
                  amountWithSymbol: '$30.00',
                  usdAmount: '30.00',
                  usdAmountWithSymbol: '$30.00',
                  amountSimpleText: '30.00',
                },
                unit_discount: 52,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 27,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 27,
            doublePoints: 0,
            mallStock: 3,
          },
          {
            sku_code: 'I035dgpnq0kn',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 718,
                attr_value_name: 'US6.5',
                attr_value_name_en: 'US6.5',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '57.99',
                amountWithSymbol: '$57.99',
                usdAmount: '57.99',
                usdAmountWithSymbol: '$57.99',
                amountSimpleText: '57.99',
              },
              salePrice: {
                amount: '27.99',
                amountWithSymbol: '$27.99',
                usdAmount: '27.99',
                usdAmountWithSymbol: '$27.99',
                amountSimpleText: '27.99',
              },
              discountPrice: {
                amount: '30.00',
                amountWithSymbol: '$30.00',
                usdAmount: '30.00',
                usdAmountWithSymbol: '$30.00',
                amountSimpleText: '30.00',
              },
              unit_discount: 52,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 27,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '57.99',
                  amountWithSymbol: '$57.99',
                  usdAmount: '57.99',
                  usdAmountWithSymbol: '$57.99',
                  amountSimpleText: '57.99',
                },
                salePrice: {
                  amount: '27.99',
                  amountWithSymbol: '$27.99',
                  usdAmount: '27.99',
                  usdAmountWithSymbol: '$27.99',
                  amountSimpleText: '27.99',
                },
                discountPrice: {
                  amount: '30.00',
                  amountWithSymbol: '$30.00',
                  usdAmount: '30.00',
                  usdAmountWithSymbol: '$30.00',
                  amountSimpleText: '30.00',
                },
                unit_discount: 52,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 27,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 27,
            doublePoints: 0,
            mallStock: 3,
          },
          {
            sku_code: 'I035dgpnvo8w',
            stock: 999,
            mall_stock: [
              {
                mall_code: '1',
                stock: 999,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 719,
                attr_value_name: 'US7',
                attr_value_name_en: 'US7',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '57.99',
                amountWithSymbol: '$57.99',
                usdAmount: '57.99',
                usdAmountWithSymbol: '$57.99',
                amountSimpleText: '57.99',
              },
              salePrice: {
                amount: '27.99',
                amountWithSymbol: '$27.99',
                usdAmount: '27.99',
                usdAmountWithSymbol: '$27.99',
                amountSimpleText: '27.99',
              },
              discountPrice: {
                amount: '30.00',
                amountWithSymbol: '$30.00',
                usdAmount: '30.00',
                usdAmountWithSymbol: '$30.00',
                amountSimpleText: '30.00',
              },
              unit_discount: 52,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 27,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '57.99',
                  amountWithSymbol: '$57.99',
                  usdAmount: '57.99',
                  usdAmountWithSymbol: '$57.99',
                  amountSimpleText: '57.99',
                },
                salePrice: {
                  amount: '27.99',
                  amountWithSymbol: '$27.99',
                  usdAmount: '27.99',
                  usdAmountWithSymbol: '$27.99',
                  amountSimpleText: '27.99',
                },
                discountPrice: {
                  amount: '30.00',
                  amountWithSymbol: '$30.00',
                  usdAmount: '30.00',
                  usdAmountWithSymbol: '$30.00',
                  amountSimpleText: '30.00',
                },
                unit_discount: 52,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 27,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 27,
            doublePoints: 0,
            mallStock: 999,
          },
          {
            sku_code: 'I035dgpo0mjr',
            stock: 999,
            mall_stock: [
              {
                mall_code: '1',
                stock: 999,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 720,
                attr_value_name: 'US7.5',
                attr_value_name_en: 'US7.5',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '57.99',
                amountWithSymbol: '$57.99',
                usdAmount: '57.99',
                usdAmountWithSymbol: '$57.99',
                amountSimpleText: '57.99',
              },
              salePrice: {
                amount: '27.99',
                amountWithSymbol: '$27.99',
                usdAmount: '27.99',
                usdAmountWithSymbol: '$27.99',
                amountSimpleText: '27.99',
              },
              discountPrice: {
                amount: '30.00',
                amountWithSymbol: '$30.00',
                usdAmount: '30.00',
                usdAmountWithSymbol: '$30.00',
                amountSimpleText: '30.00',
              },
              unit_discount: 52,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 27,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '57.99',
                  amountWithSymbol: '$57.99',
                  usdAmount: '57.99',
                  usdAmountWithSymbol: '$57.99',
                  amountSimpleText: '57.99',
                },
                salePrice: {
                  amount: '27.99',
                  amountWithSymbol: '$27.99',
                  usdAmount: '27.99',
                  usdAmountWithSymbol: '$27.99',
                  amountSimpleText: '27.99',
                },
                discountPrice: {
                  amount: '30.00',
                  amountWithSymbol: '$30.00',
                  usdAmount: '30.00',
                  usdAmountWithSymbol: '$30.00',
                  amountSimpleText: '30.00',
                },
                unit_discount: 52,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 27,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 27,
            doublePoints: 0,
            mallStock: 999,
          },
          {
            sku_code: 'I035dgpo7470',
            stock: 999,
            mall_stock: [
              {
                mall_code: '1',
                stock: 999,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 721,
                attr_value_name: 'US8',
                attr_value_name_en: 'US8',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '57.99',
                amountWithSymbol: '$57.99',
                usdAmount: '57.99',
                usdAmountWithSymbol: '$57.99',
                amountSimpleText: '57.99',
              },
              salePrice: {
                amount: '27.99',
                amountWithSymbol: '$27.99',
                usdAmount: '27.99',
                usdAmountWithSymbol: '$27.99',
                amountSimpleText: '27.99',
              },
              discountPrice: {
                amount: '30.00',
                amountWithSymbol: '$30.00',
                usdAmount: '30.00',
                usdAmountWithSymbol: '$30.00',
                amountSimpleText: '30.00',
              },
              unit_discount: 52,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 27,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '57.99',
                  amountWithSymbol: '$57.99',
                  usdAmount: '57.99',
                  usdAmountWithSymbol: '$57.99',
                  amountSimpleText: '57.99',
                },
                salePrice: {
                  amount: '27.99',
                  amountWithSymbol: '$27.99',
                  usdAmount: '27.99',
                  usdAmountWithSymbol: '$27.99',
                  amountSimpleText: '27.99',
                },
                discountPrice: {
                  amount: '30.00',
                  amountWithSymbol: '$30.00',
                  usdAmount: '30.00',
                  usdAmountWithSymbol: '$30.00',
                  amountSimpleText: '30.00',
                },
                unit_discount: 52,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 27,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 27,
            doublePoints: 0,
            mallStock: 999,
          },
          {
            sku_code: 'I035dgpodccy',
            stock: 999,
            mall_stock: [
              {
                mall_code: '1',
                stock: 999,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 722,
                attr_value_name: 'US8.5',
                attr_value_name_en: 'US8.5',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '57.99',
                amountWithSymbol: '$57.99',
                usdAmount: '57.99',
                usdAmountWithSymbol: '$57.99',
                amountSimpleText: '57.99',
              },
              salePrice: {
                amount: '27.99',
                amountWithSymbol: '$27.99',
                usdAmount: '27.99',
                usdAmountWithSymbol: '$27.99',
                amountSimpleText: '27.99',
              },
              discountPrice: {
                amount: '30.00',
                amountWithSymbol: '$30.00',
                usdAmount: '30.00',
                usdAmountWithSymbol: '$30.00',
                amountSimpleText: '30.00',
              },
              unit_discount: 52,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 27,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '57.99',
                  amountWithSymbol: '$57.99',
                  usdAmount: '57.99',
                  usdAmountWithSymbol: '$57.99',
                  amountSimpleText: '57.99',
                },
                salePrice: {
                  amount: '27.99',
                  amountWithSymbol: '$27.99',
                  usdAmount: '27.99',
                  usdAmountWithSymbol: '$27.99',
                  amountSimpleText: '27.99',
                },
                discountPrice: {
                  amount: '30.00',
                  amountWithSymbol: '$30.00',
                  usdAmount: '30.00',
                  usdAmountWithSymbol: '$30.00',
                  amountSimpleText: '30.00',
                },
                unit_discount: 52,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 27,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 27,
            doublePoints: 0,
            mallStock: 999,
          },
          {
            sku_code: 'I035dgpoiyxq',
            stock: 999,
            mall_stock: [
              {
                mall_code: '1',
                stock: 999,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 723,
                attr_value_name: 'US9',
                attr_value_name_en: 'US9',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '57.99',
                amountWithSymbol: '$57.99',
                usdAmount: '57.99',
                usdAmountWithSymbol: '$57.99',
                amountSimpleText: '57.99',
              },
              salePrice: {
                amount: '27.99',
                amountWithSymbol: '$27.99',
                usdAmount: '27.99',
                usdAmountWithSymbol: '$27.99',
                amountSimpleText: '27.99',
              },
              discountPrice: {
                amount: '30.00',
                amountWithSymbol: '$30.00',
                usdAmount: '30.00',
                usdAmountWithSymbol: '$30.00',
                amountSimpleText: '30.00',
              },
              unit_discount: 52,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 27,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '57.99',
                  amountWithSymbol: '$57.99',
                  usdAmount: '57.99',
                  usdAmountWithSymbol: '$57.99',
                  amountSimpleText: '57.99',
                },
                salePrice: {
                  amount: '27.99',
                  amountWithSymbol: '$27.99',
                  usdAmount: '27.99',
                  usdAmountWithSymbol: '$27.99',
                  amountSimpleText: '27.99',
                },
                discountPrice: {
                  amount: '30.00',
                  amountWithSymbol: '$30.00',
                  usdAmount: '30.00',
                  usdAmountWithSymbol: '$30.00',
                  amountSimpleText: '30.00',
                },
                unit_discount: 52,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 27,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 27,
            doublePoints: 0,
            mallStock: 999,
          },
          {
            sku_code: 'I035dgpons1o',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 1354,
                attr_value_name: 'US9.5',
                attr_value_name_en: 'US9.5',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '57.99',
                amountWithSymbol: '$57.99',
                usdAmount: '57.99',
                usdAmountWithSymbol: '$57.99',
                amountSimpleText: '57.99',
              },
              salePrice: {
                amount: '27.99',
                amountWithSymbol: '$27.99',
                usdAmount: '27.99',
                usdAmountWithSymbol: '$27.99',
                amountSimpleText: '27.99',
              },
              discountPrice: {
                amount: '30.00',
                amountWithSymbol: '$30.00',
                usdAmount: '30.00',
                usdAmountWithSymbol: '$30.00',
                amountSimpleText: '30.00',
              },
              unit_discount: 52,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 27,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '57.99',
                  amountWithSymbol: '$57.99',
                  usdAmount: '57.99',
                  usdAmountWithSymbol: '$57.99',
                  amountSimpleText: '57.99',
                },
                salePrice: {
                  amount: '27.99',
                  amountWithSymbol: '$27.99',
                  usdAmount: '27.99',
                  usdAmountWithSymbol: '$27.99',
                  amountSimpleText: '27.99',
                },
                discountPrice: {
                  amount: '30.00',
                  amountWithSymbol: '$30.00',
                  usdAmount: '30.00',
                  usdAmountWithSymbol: '$30.00',
                  amountSimpleText: '30.00',
                },
                unit_discount: 52,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 27,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 27,
            doublePoints: 0,
            mallStock: 3,
          },
          {
            sku_code: 'I035dgpou0c2',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 710,
                attr_value_name: 'US10',
                attr_value_name_en: 'US10',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '57.99',
                amountWithSymbol: '$57.99',
                usdAmount: '57.99',
                usdAmountWithSymbol: '$57.99',
                amountSimpleText: '57.99',
              },
              salePrice: {
                amount: '27.99',
                amountWithSymbol: '$27.99',
                usdAmount: '27.99',
                usdAmountWithSymbol: '$27.99',
                amountSimpleText: '27.99',
              },
              discountPrice: {
                amount: '30.00',
                amountWithSymbol: '$30.00',
                usdAmount: '30.00',
                usdAmountWithSymbol: '$30.00',
                amountSimpleText: '30.00',
              },
              unit_discount: 52,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 27,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '57.99',
                  amountWithSymbol: '$57.99',
                  usdAmount: '57.99',
                  usdAmountWithSymbol: '$57.99',
                  amountSimpleText: '57.99',
                },
                salePrice: {
                  amount: '27.99',
                  amountWithSymbol: '$27.99',
                  usdAmount: '27.99',
                  usdAmountWithSymbol: '$27.99',
                  amountSimpleText: '27.99',
                },
                discountPrice: {
                  amount: '30.00',
                  amountWithSymbol: '$30.00',
                  usdAmount: '30.00',
                  usdAmountWithSymbol: '$30.00',
                  amountSimpleText: '30.00',
                },
                unit_discount: 52,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 27,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 27,
            doublePoints: 0,
            mallStock: 3,
          },
          {
            sku_code: 'I035dgpp0eo4',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 711,
                attr_value_name: 'US11',
                attr_value_name_en: 'US11',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '57.99',
                amountWithSymbol: '$57.99',
                usdAmount: '57.99',
                usdAmountWithSymbol: '$57.99',
                amountSimpleText: '57.99',
              },
              salePrice: {
                amount: '27.99',
                amountWithSymbol: '$27.99',
                usdAmount: '27.99',
                usdAmountWithSymbol: '$27.99',
                amountSimpleText: '27.99',
              },
              discountPrice: {
                amount: '30.00',
                amountWithSymbol: '$30.00',
                usdAmount: '30.00',
                usdAmountWithSymbol: '$30.00',
                amountSimpleText: '30.00',
              },
              unit_discount: 52,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 27,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '57.99',
                  amountWithSymbol: '$57.99',
                  usdAmount: '57.99',
                  usdAmountWithSymbol: '$57.99',
                  amountSimpleText: '57.99',
                },
                salePrice: {
                  amount: '27.99',
                  amountWithSymbol: '$27.99',
                  usdAmount: '27.99',
                  usdAmountWithSymbol: '$27.99',
                  amountSimpleText: '27.99',
                },
                discountPrice: {
                  amount: '30.00',
                  amountWithSymbol: '$30.00',
                  usdAmount: '30.00',
                  usdAmountWithSymbol: '$30.00',
                  amountSimpleText: '30.00',
                },
                unit_discount: 52,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 27,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 27,
            doublePoints: 0,
            mallStock: 3,
          },
        ],
        skc_sale_attr: [
          {
            attr_id: 87,
            attr_name: 'Size',
            attr_value_list: [
              {
                attr_value_id: 717,
                attr_value_name: 'US6',
                attr_value_name_en: 'US6',
                attr_std_value: '',
              },
              {
                attr_value_id: 718,
                attr_value_name: 'US6.5',
                attr_value_name_en: 'US6.5',
                attr_std_value: '',
              },
              {
                attr_value_id: 719,
                attr_value_name: 'US7',
                attr_value_name_en: 'US7',
                attr_std_value: '',
              },
              {
                attr_value_id: 720,
                attr_value_name: 'US7.5',
                attr_value_name_en: 'US7.5',
                attr_std_value: '',
              },
              {
                attr_value_id: 721,
                attr_value_name: 'US8',
                attr_value_name_en: 'US8',
                attr_std_value: '',
              },
              {
                attr_value_id: 722,
                attr_value_name: 'US8.5',
                attr_value_name_en: 'US8.5',
                attr_std_value: '',
              },
              {
                attr_value_id: 723,
                attr_value_name: 'US9',
                attr_value_name_en: 'US9',
                attr_std_value: '',
              },
              {
                attr_value_id: 1354,
                attr_value_name: 'US9.5',
                attr_value_name_en: 'US9.5',
                attr_std_value: '',
              },
              {
                attr_value_id: 710,
                attr_value_name: 'US10',
                attr_value_name_en: 'US10',
                attr_std_value: '',
              },
              {
                attr_value_id: 711,
                attr_value_name: 'US11',
                attr_value_name_en: 'US11',
                attr_std_value: '',
              },
            ],
          },
        ],
      },
    },
    {
      relatedColor: '',
      cacheDataV6: '31576920@1',
      goods_id: 31576920,
      goods_sn: 'sx2403087056702470',
      goods_name: 'Men\'s Slip On Walking Shoes Non Slip Athletic Gym Workout Comfortable Lightweight Running Tennis Sneakers',
      goods_url_name: 'Men s Slip On Walking Shoes Non Slip Athletic Gym Workout Comfortable Lightweight Running Tennis Sneakers',
      cat_id: 6968,
      goods_img: '//img.ltwebstatic.com/images3_spmp/2024/03/14/79/171041665083bee5d8a9959cbc1bb50fe223996167_thumbnail_405x552.jpg',
      is_on_sale: 1,
      original_img: '//img.ltwebstatic.com/images3_spmp/2024/03/14/79/171041665083bee5d8a9959cbc1bb50fe223996167.jpg',
      goods_color_img: '//img.ltwebstatic.com/images3_spmp/2024/03/13/e8/17102944138b6bc218a93c2376fbd1221dc71aa9cf.jpg',
      supplier_id: 2264411,
      productRelationID: 'x24030870567',
      goods_img_webp: '//img.ltwebstatic.com/images3_spmp/2024/03/14/79/171041665083bee5d8a9959cbc1bb50fe223996167_thumbnail_405x552.webp',
      original_img_webp: '//img.ltwebstatic.com/images3_spmp/2024/03/14/79/171041665083bee5d8a9959cbc1bb50fe223996167.webp',
      is_pre_sale_end: 0,
      business_model: 1,
      store_code: 6596207479,
      mall_code: '1',
      ext: {
        rec_mark: 'mmd_12498|bkt|rkt|loc_3_1',
      },
      mallList: [
        {
          mall_code: '1',
          stock: 999,
          mall_code_sort: 1,
          sku_quick_ship: true,
        },
      ],
      retailPrice: {
        amount: '63.99',
        amountWithSymbol: '$63.99',
        usdAmount: '63.99',
        usdAmountWithSymbol: '$63.99',
        amountSimpleText: '63.99',
      },
      salePrice: {
        amount: '34.99',
        amountWithSymbol: '$34.99',
        usdAmount: '34.99',
        usdAmountWithSymbol: '$34.99',
        amountSimpleText: '34.99',
      },
      promotionInfo: [
      ],
      appPromotion: [
      ],
      discountPrice: {
        amount: '29.00',
        amountWithSymbol: '$29.00',
        usdAmount: '29.00',
        usdAmountWithSymbol: '$29.00',
        amountSimpleText: '29.00',
      },
      unit_discount: 45,
      original_discount: '45',
      mall_price: [
        {
          mall_code: '1',
          retailPrice: {
            amount: '63.99',
            amountWithSymbol: '$63.99',
            usdAmount: '63.99',
            usdAmountWithSymbol: '$63.99',
            amountSimpleText: '63.99',
          },
          salePrice: {
            amount: '34.99',
            amountWithSymbol: '$34.99',
            usdAmount: '34.99',
            usdAmountWithSymbol: '$34.99',
            amountSimpleText: '34.99',
          },
          discountPrice: {
            amount: '29.00',
            amountWithSymbol: '$29.00',
            usdAmount: '29.00',
            usdAmountWithSymbol: '$29.00',
            amountSimpleText: '29.00',
          },
          unit_discount: 45,
          promotion_status: null,
          promotion_logo_type: null,
          original_discount: '45',
          coupon_prices: [
          ],
        },
      ],
      mallAppPromotion: [
      ],
      mallPromotionInfo: [
      ],
      promotion_status: null,
      promotion_logo_type: null,
      coupon_prices: [
      ],
      showPrice: null,
      showTypeId: null,
      vipDiscountPrice: null,
      suitRulePrice: null,
      rule_num: null,
      discountValue: null,
      isInsurable: 0,
      promotion_discount_info: [
      ],
      coupons: [
      ],
      skc: 'sx2403087056702470',
      brand: null,
      series: null,
      series_list: [
      ],
      _series: {
      },
      _brand: {
      },
      productDetails: [
      ],
      comment: {
        comment_num: '0',
        comment_rank: '0',
      },
      sale_attr_list: {
        goods_id: '31576920',
        goods_sn: 'sx2403087056702470',
        skc_name: 'sx2403087056702470',
        sku_list: [
          {
            sku_code: 'I328prnchi7m',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 721,
                attr_value_name: 'US8',
                attr_value_name_en: 'US8',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '63.99',
                amountWithSymbol: '$63.99',
                usdAmount: '63.99',
                usdAmountWithSymbol: '$63.99',
                amountSimpleText: '63.99',
              },
              salePrice: {
                amount: '34.99',
                amountWithSymbol: '$34.99',
                usdAmount: '34.99',
                usdAmountWithSymbol: '$34.99',
                amountSimpleText: '34.99',
              },
              discountPrice: {
                amount: '29.00',
                amountWithSymbol: '$29.00',
                usdAmount: '29.00',
                usdAmountWithSymbol: '$29.00',
                amountSimpleText: '29.00',
              },
              unit_discount: 45,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 34,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '63.99',
                  amountWithSymbol: '$63.99',
                  usdAmount: '63.99',
                  usdAmountWithSymbol: '$63.99',
                  amountSimpleText: '63.99',
                },
                salePrice: {
                  amount: '34.99',
                  amountWithSymbol: '$34.99',
                  usdAmount: '34.99',
                  usdAmountWithSymbol: '$34.99',
                  amountSimpleText: '34.99',
                },
                discountPrice: {
                  amount: '29.00',
                  amountWithSymbol: '$29.00',
                  usdAmount: '29.00',
                  usdAmountWithSymbol: '$29.00',
                  amountSimpleText: '29.00',
                },
                unit_discount: 45,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 34,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 34,
            doublePoints: 0,
            mallStock: 3,
          },
          {
            sku_code: 'I328prng5ia5',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 722,
                attr_value_name: 'US8.5',
                attr_value_name_en: 'US8.5',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '63.99',
                amountWithSymbol: '$63.99',
                usdAmount: '63.99',
                usdAmountWithSymbol: '$63.99',
                amountSimpleText: '63.99',
              },
              salePrice: {
                amount: '34.99',
                amountWithSymbol: '$34.99',
                usdAmount: '34.99',
                usdAmountWithSymbol: '$34.99',
                amountSimpleText: '34.99',
              },
              discountPrice: {
                amount: '29.00',
                amountWithSymbol: '$29.00',
                usdAmount: '29.00',
                usdAmountWithSymbol: '$29.00',
                amountSimpleText: '29.00',
              },
              unit_discount: 45,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 34,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '63.99',
                  amountWithSymbol: '$63.99',
                  usdAmount: '63.99',
                  usdAmountWithSymbol: '$63.99',
                  amountSimpleText: '63.99',
                },
                salePrice: {
                  amount: '34.99',
                  amountWithSymbol: '$34.99',
                  usdAmount: '34.99',
                  usdAmountWithSymbol: '$34.99',
                  amountSimpleText: '34.99',
                },
                discountPrice: {
                  amount: '29.00',
                  amountWithSymbol: '$29.00',
                  usdAmount: '29.00',
                  usdAmountWithSymbol: '$29.00',
                  amountSimpleText: '29.00',
                },
                unit_discount: 45,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 34,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 34,
            doublePoints: 0,
            mallStock: 3,
          },
          {
            sku_code: 'I328prnlfnjt',
            stock: 999,
            mall_stock: [
              {
                mall_code: '1',
                stock: 999,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 723,
                attr_value_name: 'US9',
                attr_value_name_en: 'US9',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '63.99',
                amountWithSymbol: '$63.99',
                usdAmount: '63.99',
                usdAmountWithSymbol: '$63.99',
                amountSimpleText: '63.99',
              },
              salePrice: {
                amount: '34.99',
                amountWithSymbol: '$34.99',
                usdAmount: '34.99',
                usdAmountWithSymbol: '$34.99',
                amountSimpleText: '34.99',
              },
              discountPrice: {
                amount: '29.00',
                amountWithSymbol: '$29.00',
                usdAmount: '29.00',
                usdAmountWithSymbol: '$29.00',
                amountSimpleText: '29.00',
              },
              unit_discount: 45,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 34,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '63.99',
                  amountWithSymbol: '$63.99',
                  usdAmount: '63.99',
                  usdAmountWithSymbol: '$63.99',
                  amountSimpleText: '63.99',
                },
                salePrice: {
                  amount: '34.99',
                  amountWithSymbol: '$34.99',
                  usdAmount: '34.99',
                  usdAmountWithSymbol: '$34.99',
                  amountSimpleText: '34.99',
                },
                discountPrice: {
                  amount: '29.00',
                  amountWithSymbol: '$29.00',
                  usdAmount: '29.00',
                  usdAmountWithSymbol: '$29.00',
                  amountSimpleText: '29.00',
                },
                unit_discount: 45,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 34,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 34,
            doublePoints: 0,
            mallStock: 999,
          },
          {
            sku_code: 'I328prnrad62',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 1354,
                attr_value_name: 'US9.5',
                attr_value_name_en: 'US9.5',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '63.99',
                amountWithSymbol: '$63.99',
                usdAmount: '63.99',
                usdAmountWithSymbol: '$63.99',
                amountSimpleText: '63.99',
              },
              salePrice: {
                amount: '34.99',
                amountWithSymbol: '$34.99',
                usdAmount: '34.99',
                usdAmountWithSymbol: '$34.99',
                amountSimpleText: '34.99',
              },
              discountPrice: {
                amount: '29.00',
                amountWithSymbol: '$29.00',
                usdAmount: '29.00',
                usdAmountWithSymbol: '$29.00',
                amountSimpleText: '29.00',
              },
              unit_discount: 45,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 34,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '63.99',
                  amountWithSymbol: '$63.99',
                  usdAmount: '63.99',
                  usdAmountWithSymbol: '$63.99',
                  amountSimpleText: '63.99',
                },
                salePrice: {
                  amount: '34.99',
                  amountWithSymbol: '$34.99',
                  usdAmount: '34.99',
                  usdAmountWithSymbol: '$34.99',
                  amountSimpleText: '34.99',
                },
                discountPrice: {
                  amount: '29.00',
                  amountWithSymbol: '$29.00',
                  usdAmount: '29.00',
                  usdAmountWithSymbol: '$29.00',
                  amountSimpleText: '29.00',
                },
                unit_discount: 45,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 34,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 34,
            doublePoints: 0,
            mallStock: 3,
          },
          {
            sku_code: 'I328prmd8xci',
            stock: 999,
            mall_stock: [
              {
                mall_code: '1',
                stock: 999,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 710,
                attr_value_name: 'US10',
                attr_value_name_en: 'US10',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '63.99',
                amountWithSymbol: '$63.99',
                usdAmount: '63.99',
                usdAmountWithSymbol: '$63.99',
                amountSimpleText: '63.99',
              },
              salePrice: {
                amount: '34.99',
                amountWithSymbol: '$34.99',
                usdAmount: '34.99',
                usdAmountWithSymbol: '$34.99',
                amountSimpleText: '34.99',
              },
              discountPrice: {
                amount: '29.00',
                amountWithSymbol: '$29.00',
                usdAmount: '29.00',
                usdAmountWithSymbol: '$29.00',
                amountSimpleText: '29.00',
              },
              unit_discount: 45,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 34,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '63.99',
                  amountWithSymbol: '$63.99',
                  usdAmount: '63.99',
                  usdAmountWithSymbol: '$63.99',
                  amountSimpleText: '63.99',
                },
                salePrice: {
                  amount: '34.99',
                  amountWithSymbol: '$34.99',
                  usdAmount: '34.99',
                  usdAmountWithSymbol: '$34.99',
                  amountSimpleText: '34.99',
                },
                discountPrice: {
                  amount: '29.00',
                  amountWithSymbol: '$29.00',
                  usdAmount: '29.00',
                  usdAmountWithSymbol: '$29.00',
                  amountSimpleText: '29.00',
                },
                unit_discount: 45,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 34,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 34,
            doublePoints: 0,
            mallStock: 999,
          },
          {
            sku_code: 'I328prmj8711',
            stock: 999,
            mall_stock: [
              {
                mall_code: '1',
                stock: 999,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 1355,
                attr_value_name: 'US10.5',
                attr_value_name_en: 'US10.5',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '63.99',
                amountWithSymbol: '$63.99',
                usdAmount: '63.99',
                usdAmountWithSymbol: '$63.99',
                amountSimpleText: '63.99',
              },
              salePrice: {
                amount: '34.99',
                amountWithSymbol: '$34.99',
                usdAmount: '34.99',
                usdAmountWithSymbol: '$34.99',
                amountSimpleText: '34.99',
              },
              discountPrice: {
                amount: '29.00',
                amountWithSymbol: '$29.00',
                usdAmount: '29.00',
                usdAmountWithSymbol: '$29.00',
                amountSimpleText: '29.00',
              },
              unit_discount: 45,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 34,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '63.99',
                  amountWithSymbol: '$63.99',
                  usdAmount: '63.99',
                  usdAmountWithSymbol: '$63.99',
                  amountSimpleText: '63.99',
                },
                salePrice: {
                  amount: '34.99',
                  amountWithSymbol: '$34.99',
                  usdAmount: '34.99',
                  usdAmountWithSymbol: '$34.99',
                  amountSimpleText: '34.99',
                },
                discountPrice: {
                  amount: '29.00',
                  amountWithSymbol: '$29.00',
                  usdAmount: '29.00',
                  usdAmountWithSymbol: '$29.00',
                  amountSimpleText: '29.00',
                },
                unit_discount: 45,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 34,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 34,
            doublePoints: 0,
            mallStock: 999,
          },
          {
            sku_code: 'I328prmon1y1',
            stock: 999,
            mall_stock: [
              {
                mall_code: '1',
                stock: 999,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 711,
                attr_value_name: 'US11',
                attr_value_name_en: 'US11',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '63.99',
                amountWithSymbol: '$63.99',
                usdAmount: '63.99',
                usdAmountWithSymbol: '$63.99',
                amountSimpleText: '63.99',
              },
              salePrice: {
                amount: '34.99',
                amountWithSymbol: '$34.99',
                usdAmount: '34.99',
                usdAmountWithSymbol: '$34.99',
                amountSimpleText: '34.99',
              },
              discountPrice: {
                amount: '29.00',
                amountWithSymbol: '$29.00',
                usdAmount: '29.00',
                usdAmountWithSymbol: '$29.00',
                amountSimpleText: '29.00',
              },
              unit_discount: 45,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 34,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '63.99',
                  amountWithSymbol: '$63.99',
                  usdAmount: '63.99',
                  usdAmountWithSymbol: '$63.99',
                  amountSimpleText: '63.99',
                },
                salePrice: {
                  amount: '34.99',
                  amountWithSymbol: '$34.99',
                  usdAmount: '34.99',
                  usdAmountWithSymbol: '$34.99',
                  amountSimpleText: '34.99',
                },
                discountPrice: {
                  amount: '29.00',
                  amountWithSymbol: '$29.00',
                  usdAmount: '29.00',
                  usdAmountWithSymbol: '$29.00',
                  amountSimpleText: '29.00',
                },
                unit_discount: 45,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 34,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 34,
            doublePoints: 0,
            mallStock: 999,
          },
          {
            sku_code: 'I328prmv29ym',
            stock: 999,
            mall_stock: [
              {
                mall_code: '1',
                stock: 999,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 1356,
                attr_value_name: 'US11.5',
                attr_value_name_en: 'US11.5',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '63.99',
                amountWithSymbol: '$63.99',
                usdAmount: '63.99',
                usdAmountWithSymbol: '$63.99',
                amountSimpleText: '63.99',
              },
              salePrice: {
                amount: '34.99',
                amountWithSymbol: '$34.99',
                usdAmount: '34.99',
                usdAmountWithSymbol: '$34.99',
                amountSimpleText: '34.99',
              },
              discountPrice: {
                amount: '29.00',
                amountWithSymbol: '$29.00',
                usdAmount: '29.00',
                usdAmountWithSymbol: '$29.00',
                amountSimpleText: '29.00',
              },
              unit_discount: 45,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 34,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '63.99',
                  amountWithSymbol: '$63.99',
                  usdAmount: '63.99',
                  usdAmountWithSymbol: '$63.99',
                  amountSimpleText: '63.99',
                },
                salePrice: {
                  amount: '34.99',
                  amountWithSymbol: '$34.99',
                  usdAmount: '34.99',
                  usdAmountWithSymbol: '$34.99',
                  amountSimpleText: '34.99',
                },
                discountPrice: {
                  amount: '29.00',
                  amountWithSymbol: '$29.00',
                  usdAmount: '29.00',
                  usdAmountWithSymbol: '$29.00',
                  amountSimpleText: '29.00',
                },
                unit_discount: 45,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 34,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 34,
            doublePoints: 0,
            mallStock: 999,
          },
          {
            sku_code: 'I328prn0u947',
            stock: 999,
            mall_stock: [
              {
                mall_code: '1',
                stock: 999,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 1357,
                attr_value_name: 'US12',
                attr_value_name_en: 'US12',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '63.99',
                amountWithSymbol: '$63.99',
                usdAmount: '63.99',
                usdAmountWithSymbol: '$63.99',
                amountSimpleText: '63.99',
              },
              salePrice: {
                amount: '34.99',
                amountWithSymbol: '$34.99',
                usdAmount: '34.99',
                usdAmountWithSymbol: '$34.99',
                amountSimpleText: '34.99',
              },
              discountPrice: {
                amount: '29.00',
                amountWithSymbol: '$29.00',
                usdAmount: '29.00',
                usdAmountWithSymbol: '$29.00',
                amountSimpleText: '29.00',
              },
              unit_discount: 45,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 34,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '63.99',
                  amountWithSymbol: '$63.99',
                  usdAmount: '63.99',
                  usdAmountWithSymbol: '$63.99',
                  amountSimpleText: '63.99',
                },
                salePrice: {
                  amount: '34.99',
                  amountWithSymbol: '$34.99',
                  usdAmount: '34.99',
                  usdAmountWithSymbol: '$34.99',
                  amountSimpleText: '34.99',
                },
                discountPrice: {
                  amount: '29.00',
                  amountWithSymbol: '$29.00',
                  usdAmount: '29.00',
                  usdAmountWithSymbol: '$29.00',
                  amountSimpleText: '29.00',
                },
                unit_discount: 45,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 34,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 34,
            doublePoints: 0,
            mallStock: 999,
          },
          {
            sku_code: 'I328prn79vxd',
            stock: 2,
            mall_stock: [
              {
                mall_code: '1',
                stock: 2,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 712,
                attr_value_name: 'US13',
                attr_value_name_en: 'US13',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '63.99',
                amountWithSymbol: '$63.99',
                usdAmount: '63.99',
                usdAmountWithSymbol: '$63.99',
                amountSimpleText: '63.99',
              },
              salePrice: {
                amount: '34.99',
                amountWithSymbol: '$34.99',
                usdAmount: '34.99',
                usdAmountWithSymbol: '$34.99',
                amountSimpleText: '34.99',
              },
              discountPrice: {
                amount: '29.00',
                amountWithSymbol: '$29.00',
                usdAmount: '29.00',
                usdAmountWithSymbol: '$29.00',
                amountSimpleText: '29.00',
              },
              unit_discount: 45,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 34,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '63.99',
                  amountWithSymbol: '$63.99',
                  usdAmount: '63.99',
                  usdAmountWithSymbol: '$63.99',
                  amountSimpleText: '63.99',
                },
                salePrice: {
                  amount: '34.99',
                  amountWithSymbol: '$34.99',
                  usdAmount: '34.99',
                  usdAmountWithSymbol: '$34.99',
                  amountSimpleText: '34.99',
                },
                discountPrice: {
                  amount: '29.00',
                  amountWithSymbol: '$29.00',
                  usdAmount: '29.00',
                  usdAmountWithSymbol: '$29.00',
                  amountSimpleText: '29.00',
                },
                unit_discount: 45,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 34,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 34,
            doublePoints: 0,
            mallStock: 2,
          },
        ],
        skc_sale_attr: [
          {
            attr_id: 87,
            attr_name: 'Size',
            attr_value_list: [
              {
                attr_value_id: 721,
                attr_value_name: 'US8',
                attr_value_name_en: 'US8',
                attr_std_value: '',
              },
              {
                attr_value_id: 722,
                attr_value_name: 'US8.5',
                attr_value_name_en: 'US8.5',
                attr_std_value: '',
              },
              {
                attr_value_id: 723,
                attr_value_name: 'US9',
                attr_value_name_en: 'US9',
                attr_std_value: '',
              },
              {
                attr_value_id: 1354,
                attr_value_name: 'US9.5',
                attr_value_name_en: 'US9.5',
                attr_std_value: '',
              },
              {
                attr_value_id: 710,
                attr_value_name: 'US10',
                attr_value_name_en: 'US10',
                attr_std_value: '',
              },
              {
                attr_value_id: 1355,
                attr_value_name: 'US10.5',
                attr_value_name_en: 'US10.5',
                attr_std_value: '',
              },
              {
                attr_value_id: 711,
                attr_value_name: 'US11',
                attr_value_name_en: 'US11',
                attr_std_value: '',
              },
              {
                attr_value_id: 1356,
                attr_value_name: 'US11.5',
                attr_value_name_en: 'US11.5',
                attr_std_value: '',
              },
              {
                attr_value_id: 1357,
                attr_value_name: 'US12',
                attr_value_name_en: 'US12',
                attr_std_value: '',
              },
              {
                attr_value_id: 712,
                attr_value_name: 'US13',
                attr_value_name_en: 'US13',
                attr_std_value: '',
              },
            ],
          },
        ],
      },
    },
    {
      relatedColor: '',
      cacheDataV6: '31568987@1',
      goods_id: 31568987,
      goods_sn: 'sk2403067517011188',
      goods_name: 'Girls Wedge Sandals Summer Flatform Open Toe Platform Sandals Cute Elegant For Big Kids And Teenager',
      goods_url_name: 'Girls Wedge Sandals Summer Flatform Open Toe Platform Sandals Cute Elegant For Big Kids And Teenager',
      cat_id: 3346,
      goods_img: '//img.ltwebstatic.com/images3_spmp/2024/03/14/dd/171040809015ec321519ea8fc4d3434b7502d02eee_thumbnail_405x552.jpg',
      is_on_sale: 1,
      original_img: '//img.ltwebstatic.com/images3_spmp/2024/03/14/dd/171040809015ec321519ea8fc4d3434b7502d02eee.jpg',
      goods_color_img: '//img.ltwebstatic.com/images3_spmp/2024/03/14/5a/171040817708c2e4a80e861be974e710668dd8bc83.jpg',
      supplier_id: 2264411,
      productRelationID: 'k24030675170',
      goods_img_webp: '//img.ltwebstatic.com/images3_spmp/2024/03/14/dd/171040809015ec321519ea8fc4d3434b7502d02eee_thumbnail_405x552.webp',
      original_img_webp: '//img.ltwebstatic.com/images3_spmp/2024/03/14/dd/171040809015ec321519ea8fc4d3434b7502d02eee.webp',
      is_pre_sale_end: 0,
      business_model: 1,
      store_code: 6596207479,
      mall_code: '1',
      ext: {
        rec_mark: 'mmd_12498|bkt|rkt|loc_5_2',
      },
      mallList: [
        {
          mall_code: '1',
          stock: 999,
          mall_code_sort: 1,
          sku_quick_ship: true,
        },
      ],
      retailPrice: {
        amount: '45.99',
        amountWithSymbol: '$45.99',
        usdAmount: '45.99',
        usdAmountWithSymbol: '$45.99',
        amountSimpleText: '45.99',
      },
      salePrice: {
        amount: '22.99',
        amountWithSymbol: '$22.99',
        usdAmount: '22.99',
        usdAmountWithSymbol: '$22.99',
        amountSimpleText: '22.99',
      },
      promotionInfo: [
      ],
      appPromotion: [
      ],
      discountPrice: {
        amount: '23.00',
        amountWithSymbol: '$23.00',
        usdAmount: '23.00',
        usdAmountWithSymbol: '$23.00',
        amountSimpleText: '23.00',
      },
      unit_discount: 50,
      original_discount: '50',
      mall_price: [
        {
          mall_code: '1',
          retailPrice: {
            amount: '45.99',
            amountWithSymbol: '$45.99',
            usdAmount: '45.99',
            usdAmountWithSymbol: '$45.99',
            amountSimpleText: '45.99',
          },
          salePrice: {
            amount: '22.99',
            amountWithSymbol: '$22.99',
            usdAmount: '22.99',
            usdAmountWithSymbol: '$22.99',
            amountSimpleText: '22.99',
          },
          discountPrice: {
            amount: '23.00',
            amountWithSymbol: '$23.00',
            usdAmount: '23.00',
            usdAmountWithSymbol: '$23.00',
            amountSimpleText: '23.00',
          },
          unit_discount: 50,
          promotion_status: null,
          promotion_logo_type: null,
          original_discount: '50',
          coupon_prices: [
          ],
        },
      ],
      mallAppPromotion: [
      ],
      mallPromotionInfo: [
      ],
      promotion_status: null,
      promotion_logo_type: null,
      coupon_prices: [
      ],
      showPrice: null,
      showTypeId: null,
      vipDiscountPrice: null,
      suitRulePrice: null,
      rule_num: null,
      discountValue: null,
      isInsurable: 0,
      promotion_discount_info: [
      ],
      coupons: [
      ],
      skc: 'sk2403067517011188',
      brand: null,
      series: null,
      series_list: [
      ],
      _series: {
      },
      _brand: {
      },
      productDetails: [
      ],
      comment: {
        comment_num: '0',
        comment_rank: '0',
      },
      sale_attr_list: {
        goods_id: '31568987',
        goods_sn: 'sk2403067517011188',
        skc_name: 'sk2403067517011188',
        sku_list: [
          {
            sku_code: 'I04cbq8aenh7',
            stock: 999,
            mall_stock: [
              {
                mall_code: '1',
                stock: 999,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 709,
                attr_value_name: 'US1',
                attr_value_name_en: 'US1',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '45.99',
                amountWithSymbol: '$45.99',
                usdAmount: '45.99',
                usdAmountWithSymbol: '$45.99',
                amountSimpleText: '45.99',
              },
              salePrice: {
                amount: '22.99',
                amountWithSymbol: '$22.99',
                usdAmount: '22.99',
                usdAmountWithSymbol: '$22.99',
                amountSimpleText: '22.99',
              },
              discountPrice: {
                amount: '23.00',
                amountWithSymbol: '$23.00',
                usdAmount: '23.00',
                usdAmountWithSymbol: '$23.00',
                amountSimpleText: '23.00',
              },
              unit_discount: 50,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 22,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '45.99',
                  amountWithSymbol: '$45.99',
                  usdAmount: '45.99',
                  usdAmountWithSymbol: '$45.99',
                  amountSimpleText: '45.99',
                },
                salePrice: {
                  amount: '22.99',
                  amountWithSymbol: '$22.99',
                  usdAmount: '22.99',
                  usdAmountWithSymbol: '$22.99',
                  amountSimpleText: '22.99',
                },
                discountPrice: {
                  amount: '23.00',
                  amountWithSymbol: '$23.00',
                  usdAmount: '23.00',
                  usdAmountWithSymbol: '$23.00',
                  amountSimpleText: '23.00',
                },
                unit_discount: 50,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 22,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 22,
            doublePoints: 0,
            mallStock: 999,
          },
          {
            sku_code: 'I04cbq8ap5t4',
            stock: 999,
            mall_stock: [
              {
                mall_code: '1',
                stock: 999,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 1349,
                attr_value_name: 'US2',
                attr_value_name_en: 'US2',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '45.99',
                amountWithSymbol: '$45.99',
                usdAmount: '45.99',
                usdAmountWithSymbol: '$45.99',
                amountSimpleText: '45.99',
              },
              salePrice: {
                amount: '22.99',
                amountWithSymbol: '$22.99',
                usdAmount: '22.99',
                usdAmountWithSymbol: '$22.99',
                amountSimpleText: '22.99',
              },
              discountPrice: {
                amount: '23.00',
                amountWithSymbol: '$23.00',
                usdAmount: '23.00',
                usdAmountWithSymbol: '$23.00',
                amountSimpleText: '23.00',
              },
              unit_discount: 50,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 22,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '45.99',
                  amountWithSymbol: '$45.99',
                  usdAmount: '45.99',
                  usdAmountWithSymbol: '$45.99',
                  amountSimpleText: '45.99',
                },
                salePrice: {
                  amount: '22.99',
                  amountWithSymbol: '$22.99',
                  usdAmount: '22.99',
                  usdAmountWithSymbol: '$22.99',
                  amountSimpleText: '22.99',
                },
                discountPrice: {
                  amount: '23.00',
                  amountWithSymbol: '$23.00',
                  usdAmount: '23.00',
                  usdAmountWithSymbol: '$23.00',
                  amountSimpleText: '23.00',
                },
                unit_discount: 50,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 22,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 22,
            doublePoints: 0,
            mallStock: 999,
          },
          {
            sku_code: 'I04cbq8avmm0',
            stock: 999,
            mall_stock: [
              {
                mall_code: '1',
                stock: 999,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 714,
                attr_value_name: 'US3',
                attr_value_name_en: 'US3',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '45.99',
                amountWithSymbol: '$45.99',
                usdAmount: '45.99',
                usdAmountWithSymbol: '$45.99',
                amountSimpleText: '45.99',
              },
              salePrice: {
                amount: '22.99',
                amountWithSymbol: '$22.99',
                usdAmount: '22.99',
                usdAmountWithSymbol: '$22.99',
                amountSimpleText: '22.99',
              },
              discountPrice: {
                amount: '23.00',
                amountWithSymbol: '$23.00',
                usdAmount: '23.00',
                usdAmountWithSymbol: '$23.00',
                amountSimpleText: '23.00',
              },
              unit_discount: 50,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 22,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '45.99',
                  amountWithSymbol: '$45.99',
                  usdAmount: '45.99',
                  usdAmountWithSymbol: '$45.99',
                  amountSimpleText: '45.99',
                },
                salePrice: {
                  amount: '22.99',
                  amountWithSymbol: '$22.99',
                  usdAmount: '22.99',
                  usdAmountWithSymbol: '$22.99',
                  amountSimpleText: '22.99',
                },
                discountPrice: {
                  amount: '23.00',
                  amountWithSymbol: '$23.00',
                  usdAmount: '23.00',
                  usdAmountWithSymbol: '$23.00',
                  amountSimpleText: '23.00',
                },
                unit_discount: 50,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 22,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 22,
            doublePoints: 0,
            mallStock: 999,
          },
          {
            sku_code: 'I04cbq8bd6d4',
            stock: 999,
            mall_stock: [
              {
                mall_code: '1',
                stock: 999,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 1352,
                attr_value_name: 'US4',
                attr_value_name_en: 'US4',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '45.99',
                amountWithSymbol: '$45.99',
                usdAmount: '45.99',
                usdAmountWithSymbol: '$45.99',
                amountSimpleText: '45.99',
              },
              salePrice: {
                amount: '22.99',
                amountWithSymbol: '$22.99',
                usdAmount: '22.99',
                usdAmountWithSymbol: '$22.99',
                amountSimpleText: '22.99',
              },
              discountPrice: {
                amount: '23.00',
                amountWithSymbol: '$23.00',
                usdAmount: '23.00',
                usdAmountWithSymbol: '$23.00',
                amountSimpleText: '23.00',
              },
              unit_discount: 50,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 22,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '45.99',
                  amountWithSymbol: '$45.99',
                  usdAmount: '45.99',
                  usdAmountWithSymbol: '$45.99',
                  amountSimpleText: '45.99',
                },
                salePrice: {
                  amount: '22.99',
                  amountWithSymbol: '$22.99',
                  usdAmount: '22.99',
                  usdAmountWithSymbol: '$22.99',
                  amountSimpleText: '22.99',
                },
                discountPrice: {
                  amount: '23.00',
                  amountWithSymbol: '$23.00',
                  usdAmount: '23.00',
                  usdAmountWithSymbol: '$23.00',
                  amountSimpleText: '23.00',
                },
                unit_discount: 50,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 22,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 22,
            doublePoints: 0,
            mallStock: 999,
          },
          {
            sku_code: 'I04cbq8bhuo5',
            stock: 999,
            mall_stock: [
              {
                mall_code: '1',
                stock: 999,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 715,
                attr_value_name: 'US5',
                attr_value_name_en: 'US5',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '45.99',
                amountWithSymbol: '$45.99',
                usdAmount: '45.99',
                usdAmountWithSymbol: '$45.99',
                amountSimpleText: '45.99',
              },
              salePrice: {
                amount: '22.99',
                amountWithSymbol: '$22.99',
                usdAmount: '22.99',
                usdAmountWithSymbol: '$22.99',
                amountSimpleText: '22.99',
              },
              discountPrice: {
                amount: '23.00',
                amountWithSymbol: '$23.00',
                usdAmount: '23.00',
                usdAmountWithSymbol: '$23.00',
                amountSimpleText: '23.00',
              },
              unit_discount: 50,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 22,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '45.99',
                  amountWithSymbol: '$45.99',
                  usdAmount: '45.99',
                  usdAmountWithSymbol: '$45.99',
                  amountSimpleText: '45.99',
                },
                salePrice: {
                  amount: '22.99',
                  amountWithSymbol: '$22.99',
                  usdAmount: '22.99',
                  usdAmountWithSymbol: '$22.99',
                  amountSimpleText: '22.99',
                },
                discountPrice: {
                  amount: '23.00',
                  amountWithSymbol: '$23.00',
                  usdAmount: '23.00',
                  usdAmountWithSymbol: '$23.00',
                  amountSimpleText: '23.00',
                },
                unit_discount: 50,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 22,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 22,
            doublePoints: 0,
            mallStock: 999,
          },
          {
            sku_code: 'I04cbq8bodae',
            stock: 999,
            mall_stock: [
              {
                mall_code: '1',
                stock: 999,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 717,
                attr_value_name: 'US6',
                attr_value_name_en: 'US6',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '45.99',
                amountWithSymbol: '$45.99',
                usdAmount: '45.99',
                usdAmountWithSymbol: '$45.99',
                amountSimpleText: '45.99',
              },
              salePrice: {
                amount: '22.99',
                amountWithSymbol: '$22.99',
                usdAmount: '22.99',
                usdAmountWithSymbol: '$22.99',
                amountSimpleText: '22.99',
              },
              discountPrice: {
                amount: '23.00',
                amountWithSymbol: '$23.00',
                usdAmount: '23.00',
                usdAmountWithSymbol: '$23.00',
                amountSimpleText: '23.00',
              },
              unit_discount: 50,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 22,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '45.99',
                  amountWithSymbol: '$45.99',
                  usdAmount: '45.99',
                  usdAmountWithSymbol: '$45.99',
                  amountSimpleText: '45.99',
                },
                salePrice: {
                  amount: '22.99',
                  amountWithSymbol: '$22.99',
                  usdAmount: '22.99',
                  usdAmountWithSymbol: '$22.99',
                  amountSimpleText: '22.99',
                },
                discountPrice: {
                  amount: '23.00',
                  amountWithSymbol: '$23.00',
                  usdAmount: '23.00',
                  usdAmountWithSymbol: '$23.00',
                  amountSimpleText: '23.00',
                },
                unit_discount: 50,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 22,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 22,
            doublePoints: 0,
            mallStock: 999,
          },
          {
            sku_code: 'I04cbq8aj6ng',
            stock: 999,
            mall_stock: [
              {
                mall_code: '1',
                stock: 999,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 712,
                attr_value_name: 'US13',
                attr_value_name_en: 'US13',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '45.99',
                amountWithSymbol: '$45.99',
                usdAmount: '45.99',
                usdAmountWithSymbol: '$45.99',
                amountSimpleText: '45.99',
              },
              salePrice: {
                amount: '22.99',
                amountWithSymbol: '$22.99',
                usdAmount: '22.99',
                usdAmountWithSymbol: '$22.99',
                amountSimpleText: '22.99',
              },
              discountPrice: {
                amount: '23.00',
                amountWithSymbol: '$23.00',
                usdAmount: '23.00',
                usdAmountWithSymbol: '$23.00',
                amountSimpleText: '23.00',
              },
              unit_discount: 50,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 22,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '45.99',
                  amountWithSymbol: '$45.99',
                  usdAmount: '45.99',
                  usdAmountWithSymbol: '$45.99',
                  amountSimpleText: '45.99',
                },
                salePrice: {
                  amount: '22.99',
                  amountWithSymbol: '$22.99',
                  usdAmount: '22.99',
                  usdAmountWithSymbol: '$22.99',
                  amountSimpleText: '22.99',
                },
                discountPrice: {
                  amount: '23.00',
                  amountWithSymbol: '$23.00',
                  usdAmount: '23.00',
                  usdAmountWithSymbol: '$23.00',
                  amountSimpleText: '23.00',
                },
                unit_discount: 50,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 22,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 22,
            doublePoints: 0,
            mallStock: 999,
          },
        ],
        skc_sale_attr: [
          {
            attr_id: 87,
            attr_name: 'Size',
            attr_value_list: [
              {
                attr_value_id: 709,
                attr_value_name: 'US1',
                attr_value_name_en: 'US1',
                attr_std_value: '',
              },
              {
                attr_value_id: 1349,
                attr_value_name: 'US2',
                attr_value_name_en: 'US2',
                attr_std_value: '',
              },
              {
                attr_value_id: 714,
                attr_value_name: 'US3',
                attr_value_name_en: 'US3',
                attr_std_value: '',
              },
              {
                attr_value_id: 1352,
                attr_value_name: 'US4',
                attr_value_name_en: 'US4',
                attr_std_value: '',
              },
              {
                attr_value_id: 715,
                attr_value_name: 'US5',
                attr_value_name_en: 'US5',
                attr_std_value: '',
              },
              {
                attr_value_id: 717,
                attr_value_name: 'US6',
                attr_value_name_en: 'US6',
                attr_std_value: '',
              },
              {
                attr_value_id: 712,
                attr_value_name: 'US13',
                attr_value_name_en: 'US13',
                attr_std_value: '',
              },
            ],
          },
        ],
      },
    },
    {
      relatedColor: '',
      cacheDataV6: '31555347@1',
      goods_id: 31555347,
      goods_sn: 'sx2403077133420858',
      goods_name: 'Water Shoes For Men Women Barefoot Quick Drying Casual-Style Swim Beach Boat Hiking Fishing Diving Surf Walking Shoes',
      goods_url_name: 'Water Shoes For Men Women Barefoot Quick Drying Casual Style Swim Beach Boat Hiking Fishing Diving Surf Walking Shoes',
      cat_id: 3190,
      goods_img: '//img.ltwebstatic.com/images3_spmp/2024/03/14/58/17104154592d794ad6f0ce21a57189a321935af4d5_thumbnail_405x552.jpg',
      is_on_sale: 1,
      original_img: '//img.ltwebstatic.com/images3_spmp/2024/03/14/58/17104154592d794ad6f0ce21a57189a321935af4d5.jpg',
      goods_color_img: '//img.ltwebstatic.com/images3_spmp/2024/03/07/c3/1709782385b1058619997712410e4ea128d8447a60.jpg',
      supplier_id: 2264411,
      productRelationID: 'x24030771334',
      goods_img_webp: '//img.ltwebstatic.com/images3_spmp/2024/03/14/58/17104154592d794ad6f0ce21a57189a321935af4d5_thumbnail_405x552.webp',
      original_img_webp: '//img.ltwebstatic.com/images3_spmp/2024/03/14/58/17104154592d794ad6f0ce21a57189a321935af4d5.webp',
      is_pre_sale_end: 0,
      business_model: 1,
      store_code: 6596207479,
      mall_code: '1',
      ext: {
        rec_mark: 'mmd_12498|bkt|rkt|loc_6_3',
      },
      mallList: [
        {
          mall_code: '1',
          stock: 999,
          mall_code_sort: 1,
          sku_quick_ship: true,
        },
      ],
      retailPrice: {
        amount: '36.99',
        amountWithSymbol: '$36.99',
        usdAmount: '36.99',
        usdAmountWithSymbol: '$36.99',
        amountSimpleText: '36.99',
      },
      salePrice: {
        amount: '21.99',
        amountWithSymbol: '$21.99',
        usdAmount: '21.99',
        usdAmountWithSymbol: '$21.99',
        amountSimpleText: '21.99',
      },
      promotionInfo: [
      ],
      appPromotion: [
      ],
      discountPrice: {
        amount: '15.00',
        amountWithSymbol: '$15.00',
        usdAmount: '15.00',
        usdAmountWithSymbol: '$15.00',
        amountSimpleText: '15.00',
      },
      unit_discount: 41,
      original_discount: '41',
      mall_price: [
        {
          mall_code: '1',
          retailPrice: {
            amount: '36.99',
            amountWithSymbol: '$36.99',
            usdAmount: '36.99',
            usdAmountWithSymbol: '$36.99',
            amountSimpleText: '36.99',
          },
          salePrice: {
            amount: '21.99',
            amountWithSymbol: '$21.99',
            usdAmount: '21.99',
            usdAmountWithSymbol: '$21.99',
            amountSimpleText: '21.99',
          },
          discountPrice: {
            amount: '15.00',
            amountWithSymbol: '$15.00',
            usdAmount: '15.00',
            usdAmountWithSymbol: '$15.00',
            amountSimpleText: '15.00',
          },
          unit_discount: 41,
          promotion_status: null,
          promotion_logo_type: null,
          original_discount: '41',
          coupon_prices: [
          ],
        },
      ],
      mallAppPromotion: [
      ],
      mallPromotionInfo: [
      ],
      promotion_status: null,
      promotion_logo_type: null,
      coupon_prices: [
      ],
      showPrice: null,
      showTypeId: null,
      vipDiscountPrice: null,
      suitRulePrice: null,
      rule_num: null,
      discountValue: null,
      isInsurable: 0,
      promotion_discount_info: [
      ],
      coupons: [
      ],
      skc: 'sx2403077133420858',
      brand: null,
      series: null,
      series_list: [
      ],
      _series: {
      },
      _brand: {
      },
      productDetails: [
      ],
      comment: {
        comment_num: '0',
        comment_rank: '0',
      },
      sale_attr_list: {
        goods_id: '31555347',
        goods_sn: 'sx2403077133420858',
        skc_name: 'sx2403077133420858',
        sku_list: [
          {
            sku_code: 'I5otjt635jrv',
            stock: 2,
            mall_stock: [
              {
                mall_code: '1',
                stock: 2,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 715,
                attr_value_name: 'US5',
                attr_value_name_en: 'US5',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '36.99',
                amountWithSymbol: '$36.99',
                usdAmount: '36.99',
                usdAmountWithSymbol: '$36.99',
                amountSimpleText: '36.99',
              },
              salePrice: {
                amount: '21.99',
                amountWithSymbol: '$21.99',
                usdAmount: '21.99',
                usdAmountWithSymbol: '$21.99',
                amountSimpleText: '21.99',
              },
              discountPrice: {
                amount: '15.00',
                amountWithSymbol: '$15.00',
                usdAmount: '15.00',
                usdAmountWithSymbol: '$15.00',
                amountSimpleText: '15.00',
              },
              unit_discount: 41,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 21,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '36.99',
                  amountWithSymbol: '$36.99',
                  usdAmount: '36.99',
                  usdAmountWithSymbol: '$36.99',
                  amountSimpleText: '36.99',
                },
                salePrice: {
                  amount: '21.99',
                  amountWithSymbol: '$21.99',
                  usdAmount: '21.99',
                  usdAmountWithSymbol: '$21.99',
                  amountSimpleText: '21.99',
                },
                discountPrice: {
                  amount: '15.00',
                  amountWithSymbol: '$15.00',
                  usdAmount: '15.00',
                  usdAmountWithSymbol: '$15.00',
                  amountSimpleText: '15.00',
                },
                unit_discount: 41,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 21,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 21,
            doublePoints: 0,
            mallStock: 2,
          },
          {
            sku_code: 'I5otjt68dp85',
            stock: 2,
            mall_stock: [
              {
                mall_code: '1',
                stock: 2,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 716,
                attr_value_name: 'US5.5',
                attr_value_name_en: 'US5.5',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '36.99',
                amountWithSymbol: '$36.99',
                usdAmount: '36.99',
                usdAmountWithSymbol: '$36.99',
                amountSimpleText: '36.99',
              },
              salePrice: {
                amount: '21.99',
                amountWithSymbol: '$21.99',
                usdAmount: '21.99',
                usdAmountWithSymbol: '$21.99',
                amountSimpleText: '21.99',
              },
              discountPrice: {
                amount: '15.00',
                amountWithSymbol: '$15.00',
                usdAmount: '15.00',
                usdAmountWithSymbol: '$15.00',
                amountSimpleText: '15.00',
              },
              unit_discount: 41,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 21,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '36.99',
                  amountWithSymbol: '$36.99',
                  usdAmount: '36.99',
                  usdAmountWithSymbol: '$36.99',
                  amountSimpleText: '36.99',
                },
                salePrice: {
                  amount: '21.99',
                  amountWithSymbol: '$21.99',
                  usdAmount: '21.99',
                  usdAmountWithSymbol: '$21.99',
                  amountSimpleText: '21.99',
                },
                discountPrice: {
                  amount: '15.00',
                  amountWithSymbol: '$15.00',
                  usdAmount: '15.00',
                  usdAmountWithSymbol: '$15.00',
                  amountSimpleText: '15.00',
                },
                unit_discount: 41,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 21,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 21,
            doublePoints: 0,
            mallStock: 2,
          },
          {
            sku_code: 'I5otjt6d4gts',
            stock: 2,
            mall_stock: [
              {
                mall_code: '1',
                stock: 2,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 717,
                attr_value_name: 'US6',
                attr_value_name_en: 'US6',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '36.99',
                amountWithSymbol: '$36.99',
                usdAmount: '36.99',
                usdAmountWithSymbol: '$36.99',
                amountSimpleText: '36.99',
              },
              salePrice: {
                amount: '21.99',
                amountWithSymbol: '$21.99',
                usdAmount: '21.99',
                usdAmountWithSymbol: '$21.99',
                amountSimpleText: '21.99',
              },
              discountPrice: {
                amount: '15.00',
                amountWithSymbol: '$15.00',
                usdAmount: '15.00',
                usdAmountWithSymbol: '$15.00',
                amountSimpleText: '15.00',
              },
              unit_discount: 41,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 21,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '36.99',
                  amountWithSymbol: '$36.99',
                  usdAmount: '36.99',
                  usdAmountWithSymbol: '$36.99',
                  amountSimpleText: '36.99',
                },
                salePrice: {
                  amount: '21.99',
                  amountWithSymbol: '$21.99',
                  usdAmount: '21.99',
                  usdAmountWithSymbol: '$21.99',
                  amountSimpleText: '21.99',
                },
                discountPrice: {
                  amount: '15.00',
                  amountWithSymbol: '$15.00',
                  usdAmount: '15.00',
                  usdAmountWithSymbol: '$15.00',
                  amountSimpleText: '15.00',
                },
                unit_discount: 41,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 21,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 21,
            doublePoints: 0,
            mallStock: 2,
          },
          {
            sku_code: 'I5otjt6hos2w',
            stock: 2,
            mall_stock: [
              {
                mall_code: '1',
                stock: 2,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 718,
                attr_value_name: 'US6.5',
                attr_value_name_en: 'US6.5',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '36.99',
                amountWithSymbol: '$36.99',
                usdAmount: '36.99',
                usdAmountWithSymbol: '$36.99',
                amountSimpleText: '36.99',
              },
              salePrice: {
                amount: '21.99',
                amountWithSymbol: '$21.99',
                usdAmount: '21.99',
                usdAmountWithSymbol: '$21.99',
                amountSimpleText: '21.99',
              },
              discountPrice: {
                amount: '15.00',
                amountWithSymbol: '$15.00',
                usdAmount: '15.00',
                usdAmountWithSymbol: '$15.00',
                amountSimpleText: '15.00',
              },
              unit_discount: 41,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 21,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '36.99',
                  amountWithSymbol: '$36.99',
                  usdAmount: '36.99',
                  usdAmountWithSymbol: '$36.99',
                  amountSimpleText: '36.99',
                },
                salePrice: {
                  amount: '21.99',
                  amountWithSymbol: '$21.99',
                  usdAmount: '21.99',
                  usdAmountWithSymbol: '$21.99',
                  amountSimpleText: '21.99',
                },
                discountPrice: {
                  amount: '15.00',
                  amountWithSymbol: '$15.00',
                  usdAmount: '15.00',
                  usdAmountWithSymbol: '$15.00',
                  amountSimpleText: '15.00',
                },
                unit_discount: 41,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 21,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 21,
            doublePoints: 0,
            mallStock: 2,
          },
          {
            sku_code: 'I5otjt6lzc4g',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 719,
                attr_value_name: 'US7',
                attr_value_name_en: 'US7',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '36.99',
                amountWithSymbol: '$36.99',
                usdAmount: '36.99',
                usdAmountWithSymbol: '$36.99',
                amountSimpleText: '36.99',
              },
              salePrice: {
                amount: '21.99',
                amountWithSymbol: '$21.99',
                usdAmount: '21.99',
                usdAmountWithSymbol: '$21.99',
                amountSimpleText: '21.99',
              },
              discountPrice: {
                amount: '15.00',
                amountWithSymbol: '$15.00',
                usdAmount: '15.00',
                usdAmountWithSymbol: '$15.00',
                amountSimpleText: '15.00',
              },
              unit_discount: 41,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 21,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '36.99',
                  amountWithSymbol: '$36.99',
                  usdAmount: '36.99',
                  usdAmountWithSymbol: '$36.99',
                  amountSimpleText: '36.99',
                },
                salePrice: {
                  amount: '21.99',
                  amountWithSymbol: '$21.99',
                  usdAmount: '21.99',
                  usdAmountWithSymbol: '$21.99',
                  amountSimpleText: '21.99',
                },
                discountPrice: {
                  amount: '15.00',
                  amountWithSymbol: '$15.00',
                  usdAmount: '15.00',
                  usdAmountWithSymbol: '$15.00',
                  amountSimpleText: '15.00',
                },
                unit_discount: 41,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 21,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 21,
            doublePoints: 0,
            mallStock: 3,
          },
          {
            sku_code: 'I5otjt6p3f9d',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 720,
                attr_value_name: 'US7.5',
                attr_value_name_en: 'US7.5',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '36.99',
                amountWithSymbol: '$36.99',
                usdAmount: '36.99',
                usdAmountWithSymbol: '$36.99',
                amountSimpleText: '36.99',
              },
              salePrice: {
                amount: '21.99',
                amountWithSymbol: '$21.99',
                usdAmount: '21.99',
                usdAmountWithSymbol: '$21.99',
                amountSimpleText: '21.99',
              },
              discountPrice: {
                amount: '15.00',
                amountWithSymbol: '$15.00',
                usdAmount: '15.00',
                usdAmountWithSymbol: '$15.00',
                amountSimpleText: '15.00',
              },
              unit_discount: 41,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 21,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '36.99',
                  amountWithSymbol: '$36.99',
                  usdAmount: '36.99',
                  usdAmountWithSymbol: '$36.99',
                  amountSimpleText: '36.99',
                },
                salePrice: {
                  amount: '21.99',
                  amountWithSymbol: '$21.99',
                  usdAmount: '21.99',
                  usdAmountWithSymbol: '$21.99',
                  amountSimpleText: '21.99',
                },
                discountPrice: {
                  amount: '15.00',
                  amountWithSymbol: '$15.00',
                  usdAmount: '15.00',
                  usdAmountWithSymbol: '$15.00',
                  amountSimpleText: '15.00',
                },
                unit_discount: 41,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 21,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 21,
            doublePoints: 0,
            mallStock: 3,
          },
          {
            sku_code: 'I5otjt6tbl8q',
            stock: 2,
            mall_stock: [
              {
                mall_code: '1',
                stock: 2,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 721,
                attr_value_name: 'US8',
                attr_value_name_en: 'US8',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '36.99',
                amountWithSymbol: '$36.99',
                usdAmount: '36.99',
                usdAmountWithSymbol: '$36.99',
                amountSimpleText: '36.99',
              },
              salePrice: {
                amount: '21.99',
                amountWithSymbol: '$21.99',
                usdAmount: '21.99',
                usdAmountWithSymbol: '$21.99',
                amountSimpleText: '21.99',
              },
              discountPrice: {
                amount: '15.00',
                amountWithSymbol: '$15.00',
                usdAmount: '15.00',
                usdAmountWithSymbol: '$15.00',
                amountSimpleText: '15.00',
              },
              unit_discount: 41,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 21,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '36.99',
                  amountWithSymbol: '$36.99',
                  usdAmount: '36.99',
                  usdAmountWithSymbol: '$36.99',
                  amountSimpleText: '36.99',
                },
                salePrice: {
                  amount: '21.99',
                  amountWithSymbol: '$21.99',
                  usdAmount: '21.99',
                  usdAmountWithSymbol: '$21.99',
                  amountSimpleText: '21.99',
                },
                discountPrice: {
                  amount: '15.00',
                  amountWithSymbol: '$15.00',
                  usdAmount: '15.00',
                  usdAmountWithSymbol: '$15.00',
                  amountSimpleText: '15.00',
                },
                unit_discount: 41,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 21,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 21,
            doublePoints: 0,
            mallStock: 2,
          },
          {
            sku_code: 'I5otjt6z9033',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 722,
                attr_value_name: 'US8.5',
                attr_value_name_en: 'US8.5',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '36.99',
                amountWithSymbol: '$36.99',
                usdAmount: '36.99',
                usdAmountWithSymbol: '$36.99',
                amountSimpleText: '36.99',
              },
              salePrice: {
                amount: '21.99',
                amountWithSymbol: '$21.99',
                usdAmount: '21.99',
                usdAmountWithSymbol: '$21.99',
                amountSimpleText: '21.99',
              },
              discountPrice: {
                amount: '15.00',
                amountWithSymbol: '$15.00',
                usdAmount: '15.00',
                usdAmountWithSymbol: '$15.00',
                amountSimpleText: '15.00',
              },
              unit_discount: 41,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 21,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '36.99',
                  amountWithSymbol: '$36.99',
                  usdAmount: '36.99',
                  usdAmountWithSymbol: '$36.99',
                  amountSimpleText: '36.99',
                },
                salePrice: {
                  amount: '21.99',
                  amountWithSymbol: '$21.99',
                  usdAmount: '21.99',
                  usdAmountWithSymbol: '$21.99',
                  amountSimpleText: '21.99',
                },
                discountPrice: {
                  amount: '15.00',
                  amountWithSymbol: '$15.00',
                  usdAmount: '15.00',
                  usdAmountWithSymbol: '$15.00',
                  amountSimpleText: '15.00',
                },
                unit_discount: 41,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 21,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 21,
            doublePoints: 0,
            mallStock: 3,
          },
          {
            sku_code: 'I5otjt72y86u',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 723,
                attr_value_name: 'US9',
                attr_value_name_en: 'US9',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '36.99',
                amountWithSymbol: '$36.99',
                usdAmount: '36.99',
                usdAmountWithSymbol: '$36.99',
                amountSimpleText: '36.99',
              },
              salePrice: {
                amount: '21.99',
                amountWithSymbol: '$21.99',
                usdAmount: '21.99',
                usdAmountWithSymbol: '$21.99',
                amountSimpleText: '21.99',
              },
              discountPrice: {
                amount: '15.00',
                amountWithSymbol: '$15.00',
                usdAmount: '15.00',
                usdAmountWithSymbol: '$15.00',
                amountSimpleText: '15.00',
              },
              unit_discount: 41,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 21,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '36.99',
                  amountWithSymbol: '$36.99',
                  usdAmount: '36.99',
                  usdAmountWithSymbol: '$36.99',
                  amountSimpleText: '36.99',
                },
                salePrice: {
                  amount: '21.99',
                  amountWithSymbol: '$21.99',
                  usdAmount: '21.99',
                  usdAmountWithSymbol: '$21.99',
                  amountSimpleText: '21.99',
                },
                discountPrice: {
                  amount: '15.00',
                  amountWithSymbol: '$15.00',
                  usdAmount: '15.00',
                  usdAmountWithSymbol: '$15.00',
                  amountSimpleText: '15.00',
                },
                unit_discount: 41,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 21,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 21,
            doublePoints: 0,
            mallStock: 3,
          },
          {
            sku_code: 'I5otjt77mmpj',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 1354,
                attr_value_name: 'US9.5',
                attr_value_name_en: 'US9.5',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '36.99',
                amountWithSymbol: '$36.99',
                usdAmount: '36.99',
                usdAmountWithSymbol: '$36.99',
                amountSimpleText: '36.99',
              },
              salePrice: {
                amount: '21.99',
                amountWithSymbol: '$21.99',
                usdAmount: '21.99',
                usdAmountWithSymbol: '$21.99',
                amountSimpleText: '21.99',
              },
              discountPrice: {
                amount: '15.00',
                amountWithSymbol: '$15.00',
                usdAmount: '15.00',
                usdAmountWithSymbol: '$15.00',
                amountSimpleText: '15.00',
              },
              unit_discount: 41,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 21,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '36.99',
                  amountWithSymbol: '$36.99',
                  usdAmount: '36.99',
                  usdAmountWithSymbol: '$36.99',
                  amountSimpleText: '36.99',
                },
                salePrice: {
                  amount: '21.99',
                  amountWithSymbol: '$21.99',
                  usdAmount: '21.99',
                  usdAmountWithSymbol: '$21.99',
                  amountSimpleText: '21.99',
                },
                discountPrice: {
                  amount: '15.00',
                  amountWithSymbol: '$15.00',
                  usdAmount: '15.00',
                  usdAmountWithSymbol: '$15.00',
                  amountSimpleText: '15.00',
                },
                unit_discount: 41,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 21,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 21,
            doublePoints: 0,
            mallStock: 3,
          },
          {
            sku_code: 'I5otjt7dj3b3',
            stock: 999,
            mall_stock: [
              {
                mall_code: '1',
                stock: 999,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 710,
                attr_value_name: 'US10',
                attr_value_name_en: 'US10',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '36.99',
                amountWithSymbol: '$36.99',
                usdAmount: '36.99',
                usdAmountWithSymbol: '$36.99',
                amountSimpleText: '36.99',
              },
              salePrice: {
                amount: '21.99',
                amountWithSymbol: '$21.99',
                usdAmount: '21.99',
                usdAmountWithSymbol: '$21.99',
                amountSimpleText: '21.99',
              },
              discountPrice: {
                amount: '15.00',
                amountWithSymbol: '$15.00',
                usdAmount: '15.00',
                usdAmountWithSymbol: '$15.00',
                amountSimpleText: '15.00',
              },
              unit_discount: 41,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 21,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '36.99',
                  amountWithSymbol: '$36.99',
                  usdAmount: '36.99',
                  usdAmountWithSymbol: '$36.99',
                  amountSimpleText: '36.99',
                },
                salePrice: {
                  amount: '21.99',
                  amountWithSymbol: '$21.99',
                  usdAmount: '21.99',
                  usdAmountWithSymbol: '$21.99',
                  amountSimpleText: '21.99',
                },
                discountPrice: {
                  amount: '15.00',
                  amountWithSymbol: '$15.00',
                  usdAmount: '15.00',
                  usdAmountWithSymbol: '$15.00',
                  amountSimpleText: '15.00',
                },
                unit_discount: 41,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 21,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 21,
            doublePoints: 0,
            mallStock: 999,
          },
          {
            sku_code: 'I5otjt7hzcii',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 1355,
                attr_value_name: 'US10.5',
                attr_value_name_en: 'US10.5',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '36.99',
                amountWithSymbol: '$36.99',
                usdAmount: '36.99',
                usdAmountWithSymbol: '$36.99',
                amountSimpleText: '36.99',
              },
              salePrice: {
                amount: '21.99',
                amountWithSymbol: '$21.99',
                usdAmount: '21.99',
                usdAmountWithSymbol: '$21.99',
                amountSimpleText: '21.99',
              },
              discountPrice: {
                amount: '15.00',
                amountWithSymbol: '$15.00',
                usdAmount: '15.00',
                usdAmountWithSymbol: '$15.00',
                amountSimpleText: '15.00',
              },
              unit_discount: 41,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 21,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '36.99',
                  amountWithSymbol: '$36.99',
                  usdAmount: '36.99',
                  usdAmountWithSymbol: '$36.99',
                  amountSimpleText: '36.99',
                },
                salePrice: {
                  amount: '21.99',
                  amountWithSymbol: '$21.99',
                  usdAmount: '21.99',
                  usdAmountWithSymbol: '$21.99',
                  amountSimpleText: '21.99',
                },
                discountPrice: {
                  amount: '15.00',
                  amountWithSymbol: '$15.00',
                  usdAmount: '15.00',
                  usdAmountWithSymbol: '$15.00',
                  amountSimpleText: '15.00',
                },
                unit_discount: 41,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 21,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 21,
            doublePoints: 0,
            mallStock: 3,
          },
          {
            sku_code: 'I5otjt7obhn1',
            stock: 999,
            mall_stock: [
              {
                mall_code: '1',
                stock: 999,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 711,
                attr_value_name: 'US11',
                attr_value_name_en: 'US11',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '36.99',
                amountWithSymbol: '$36.99',
                usdAmount: '36.99',
                usdAmountWithSymbol: '$36.99',
                amountSimpleText: '36.99',
              },
              salePrice: {
                amount: '21.99',
                amountWithSymbol: '$21.99',
                usdAmount: '21.99',
                usdAmountWithSymbol: '$21.99',
                amountSimpleText: '21.99',
              },
              discountPrice: {
                amount: '15.00',
                amountWithSymbol: '$15.00',
                usdAmount: '15.00',
                usdAmountWithSymbol: '$15.00',
                amountSimpleText: '15.00',
              },
              unit_discount: 41,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 21,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '36.99',
                  amountWithSymbol: '$36.99',
                  usdAmount: '36.99',
                  usdAmountWithSymbol: '$36.99',
                  amountSimpleText: '36.99',
                },
                salePrice: {
                  amount: '21.99',
                  amountWithSymbol: '$21.99',
                  usdAmount: '21.99',
                  usdAmountWithSymbol: '$21.99',
                  amountSimpleText: '21.99',
                },
                discountPrice: {
                  amount: '15.00',
                  amountWithSymbol: '$15.00',
                  usdAmount: '15.00',
                  usdAmountWithSymbol: '$15.00',
                  amountSimpleText: '15.00',
                },
                unit_discount: 41,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 21,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 21,
            doublePoints: 0,
            mallStock: 999,
          },
          {
            sku_code: 'I5otjt5eficz',
            stock: 999,
            mall_stock: [
              {
                mall_code: '1',
                stock: 999,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 1356,
                attr_value_name: 'US11.5',
                attr_value_name_en: 'US11.5',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '36.99',
                amountWithSymbol: '$36.99',
                usdAmount: '36.99',
                usdAmountWithSymbol: '$36.99',
                amountSimpleText: '36.99',
              },
              salePrice: {
                amount: '21.99',
                amountWithSymbol: '$21.99',
                usdAmount: '21.99',
                usdAmountWithSymbol: '$21.99',
                amountSimpleText: '21.99',
              },
              discountPrice: {
                amount: '15.00',
                amountWithSymbol: '$15.00',
                usdAmount: '15.00',
                usdAmountWithSymbol: '$15.00',
                amountSimpleText: '15.00',
              },
              unit_discount: 41,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 21,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '36.99',
                  amountWithSymbol: '$36.99',
                  usdAmount: '36.99',
                  usdAmountWithSymbol: '$36.99',
                  amountSimpleText: '36.99',
                },
                salePrice: {
                  amount: '21.99',
                  amountWithSymbol: '$21.99',
                  usdAmount: '21.99',
                  usdAmountWithSymbol: '$21.99',
                  amountSimpleText: '21.99',
                },
                discountPrice: {
                  amount: '15.00',
                  amountWithSymbol: '$15.00',
                  usdAmount: '15.00',
                  usdAmountWithSymbol: '$15.00',
                  amountSimpleText: '15.00',
                },
                unit_discount: 41,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 21,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 21,
            doublePoints: 0,
            mallStock: 999,
          },
          {
            sku_code: 'I5otjt5j6975',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 1357,
                attr_value_name: 'US12',
                attr_value_name_en: 'US12',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '36.99',
                amountWithSymbol: '$36.99',
                usdAmount: '36.99',
                usdAmountWithSymbol: '$36.99',
                amountSimpleText: '36.99',
              },
              salePrice: {
                amount: '21.99',
                amountWithSymbol: '$21.99',
                usdAmount: '21.99',
                usdAmountWithSymbol: '$21.99',
                amountSimpleText: '21.99',
              },
              discountPrice: {
                amount: '15.00',
                amountWithSymbol: '$15.00',
                usdAmount: '15.00',
                usdAmountWithSymbol: '$15.00',
                amountSimpleText: '15.00',
              },
              unit_discount: 41,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 21,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '36.99',
                  amountWithSymbol: '$36.99',
                  usdAmount: '36.99',
                  usdAmountWithSymbol: '$36.99',
                  amountSimpleText: '36.99',
                },
                salePrice: {
                  amount: '21.99',
                  amountWithSymbol: '$21.99',
                  usdAmount: '21.99',
                  usdAmountWithSymbol: '$21.99',
                  amountSimpleText: '21.99',
                },
                discountPrice: {
                  amount: '15.00',
                  amountWithSymbol: '$15.00',
                  usdAmount: '15.00',
                  usdAmountWithSymbol: '$15.00',
                  amountSimpleText: '15.00',
                },
                unit_discount: 41,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 21,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 21,
            doublePoints: 0,
            mallStock: 3,
          },
          {
            sku_code: 'I5otjt5m7int',
            stock: 0,
            mall_stock: [
              {
                mall_code: '1',
                stock: 0,
                mall_code_sort: 1,
                sku_quick_ship: false,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 1358,
                attr_value_name: 'US12.5',
                attr_value_name_en: 'US12.5',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '36.99',
                amountWithSymbol: '$36.99',
                usdAmount: '36.99',
                usdAmountWithSymbol: '$36.99',
                amountSimpleText: '36.99',
              },
              salePrice: {
                amount: '21.99',
                amountWithSymbol: '$21.99',
                usdAmount: '21.99',
                usdAmountWithSymbol: '$21.99',
                amountSimpleText: '21.99',
              },
              discountPrice: {
                amount: '15.00',
                amountWithSymbol: '$15.00',
                usdAmount: '15.00',
                usdAmountWithSymbol: '$15.00',
                amountSimpleText: '15.00',
              },
              unit_discount: 41,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 21,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '36.99',
                  amountWithSymbol: '$36.99',
                  usdAmount: '36.99',
                  usdAmountWithSymbol: '$36.99',
                  amountSimpleText: '36.99',
                },
                salePrice: {
                  amount: '21.99',
                  amountWithSymbol: '$21.99',
                  usdAmount: '21.99',
                  usdAmountWithSymbol: '$21.99',
                  amountSimpleText: '21.99',
                },
                discountPrice: {
                  amount: '15.00',
                  amountWithSymbol: '$15.00',
                  usdAmount: '15.00',
                  usdAmountWithSymbol: '$15.00',
                  amountSimpleText: '15.00',
                },
                unit_discount: 41,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 21,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 21,
            doublePoints: 0,
            mallStock: 0,
          },
          {
            sku_code: 'I5otjt5pg3m6',
            stock: 999,
            mall_stock: [
              {
                mall_code: '1',
                stock: 999,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 712,
                attr_value_name: 'US13',
                attr_value_name_en: 'US13',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '36.99',
                amountWithSymbol: '$36.99',
                usdAmount: '36.99',
                usdAmountWithSymbol: '$36.99',
                amountSimpleText: '36.99',
              },
              salePrice: {
                amount: '21.99',
                amountWithSymbol: '$21.99',
                usdAmount: '21.99',
                usdAmountWithSymbol: '$21.99',
                amountSimpleText: '21.99',
              },
              discountPrice: {
                amount: '15.00',
                amountWithSymbol: '$15.00',
                usdAmount: '15.00',
                usdAmountWithSymbol: '$15.00',
                amountSimpleText: '15.00',
              },
              unit_discount: 41,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 21,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '36.99',
                  amountWithSymbol: '$36.99',
                  usdAmount: '36.99',
                  usdAmountWithSymbol: '$36.99',
                  amountSimpleText: '36.99',
                },
                salePrice: {
                  amount: '21.99',
                  amountWithSymbol: '$21.99',
                  usdAmount: '21.99',
                  usdAmountWithSymbol: '$21.99',
                  amountSimpleText: '21.99',
                },
                discountPrice: {
                  amount: '15.00',
                  amountWithSymbol: '$15.00',
                  usdAmount: '15.00',
                  usdAmountWithSymbol: '$15.00',
                  amountSimpleText: '15.00',
                },
                unit_discount: 41,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 21,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 21,
            doublePoints: 0,
            mallStock: 999,
          },
          {
            sku_code: 'I5otjt5sx0iw',
            stock: 999,
            mall_stock: [
              {
                mall_code: '1',
                stock: 999,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 1359,
                attr_value_name: 'US13.5',
                attr_value_name_en: 'US13.5',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '36.99',
                amountWithSymbol: '$36.99',
                usdAmount: '36.99',
                usdAmountWithSymbol: '$36.99',
                amountSimpleText: '36.99',
              },
              salePrice: {
                amount: '21.99',
                amountWithSymbol: '$21.99',
                usdAmount: '21.99',
                usdAmountWithSymbol: '$21.99',
                amountSimpleText: '21.99',
              },
              discountPrice: {
                amount: '15.00',
                amountWithSymbol: '$15.00',
                usdAmount: '15.00',
                usdAmountWithSymbol: '$15.00',
                amountSimpleText: '15.00',
              },
              unit_discount: 41,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 21,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '36.99',
                  amountWithSymbol: '$36.99',
                  usdAmount: '36.99',
                  usdAmountWithSymbol: '$36.99',
                  amountSimpleText: '36.99',
                },
                salePrice: {
                  amount: '21.99',
                  amountWithSymbol: '$21.99',
                  usdAmount: '21.99',
                  usdAmountWithSymbol: '$21.99',
                  amountSimpleText: '21.99',
                },
                discountPrice: {
                  amount: '15.00',
                  amountWithSymbol: '$15.00',
                  usdAmount: '15.00',
                  usdAmountWithSymbol: '$15.00',
                  amountSimpleText: '15.00',
                },
                unit_discount: 41,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 21,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 21,
            doublePoints: 0,
            mallStock: 999,
          },
          {
            sku_code: 'I5otjt5y6wyk',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 1425732,
                attr_value_name: 'US14.5',
                attr_value_name_en: 'US14.5',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '36.99',
                amountWithSymbol: '$36.99',
                usdAmount: '36.99',
                usdAmountWithSymbol: '$36.99',
                amountSimpleText: '36.99',
              },
              salePrice: {
                amount: '21.99',
                amountWithSymbol: '$21.99',
                usdAmount: '21.99',
                usdAmountWithSymbol: '$21.99',
                amountSimpleText: '21.99',
              },
              discountPrice: {
                amount: '15.00',
                amountWithSymbol: '$15.00',
                usdAmount: '15.00',
                usdAmountWithSymbol: '$15.00',
                amountSimpleText: '15.00',
              },
              unit_discount: 41,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 21,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '36.99',
                  amountWithSymbol: '$36.99',
                  usdAmount: '36.99',
                  usdAmountWithSymbol: '$36.99',
                  amountSimpleText: '36.99',
                },
                salePrice: {
                  amount: '21.99',
                  amountWithSymbol: '$21.99',
                  usdAmount: '21.99',
                  usdAmountWithSymbol: '$21.99',
                  amountSimpleText: '21.99',
                },
                discountPrice: {
                  amount: '15.00',
                  amountWithSymbol: '$15.00',
                  usdAmount: '15.00',
                  usdAmountWithSymbol: '$15.00',
                  amountSimpleText: '15.00',
                },
                unit_discount: 41,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 21,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 21,
            doublePoints: 0,
            mallStock: 3,
          },
        ],
        skc_sale_attr: [
          {
            attr_id: 87,
            attr_name: 'Size',
            attr_value_list: [
              {
                attr_value_id: 715,
                attr_value_name: 'US5',
                attr_value_name_en: 'US5',
                attr_std_value: '',
              },
              {
                attr_value_id: 716,
                attr_value_name: 'US5.5',
                attr_value_name_en: 'US5.5',
                attr_std_value: '',
              },
              {
                attr_value_id: 717,
                attr_value_name: 'US6',
                attr_value_name_en: 'US6',
                attr_std_value: '',
              },
              {
                attr_value_id: 718,
                attr_value_name: 'US6.5',
                attr_value_name_en: 'US6.5',
                attr_std_value: '',
              },
              {
                attr_value_id: 719,
                attr_value_name: 'US7',
                attr_value_name_en: 'US7',
                attr_std_value: '',
              },
              {
                attr_value_id: 720,
                attr_value_name: 'US7.5',
                attr_value_name_en: 'US7.5',
                attr_std_value: '',
              },
              {
                attr_value_id: 721,
                attr_value_name: 'US8',
                attr_value_name_en: 'US8',
                attr_std_value: '',
              },
              {
                attr_value_id: 722,
                attr_value_name: 'US8.5',
                attr_value_name_en: 'US8.5',
                attr_std_value: '',
              },
              {
                attr_value_id: 723,
                attr_value_name: 'US9',
                attr_value_name_en: 'US9',
                attr_std_value: '',
              },
              {
                attr_value_id: 1354,
                attr_value_name: 'US9.5',
                attr_value_name_en: 'US9.5',
                attr_std_value: '',
              },
              {
                attr_value_id: 710,
                attr_value_name: 'US10',
                attr_value_name_en: 'US10',
                attr_std_value: '',
              },
              {
                attr_value_id: 1355,
                attr_value_name: 'US10.5',
                attr_value_name_en: 'US10.5',
                attr_std_value: '',
              },
              {
                attr_value_id: 711,
                attr_value_name: 'US11',
                attr_value_name_en: 'US11',
                attr_std_value: '',
              },
              {
                attr_value_id: 1356,
                attr_value_name: 'US11.5',
                attr_value_name_en: 'US11.5',
                attr_std_value: '',
              },
              {
                attr_value_id: 1357,
                attr_value_name: 'US12',
                attr_value_name_en: 'US12',
                attr_std_value: '',
              },
              {
                attr_value_id: 1358,
                attr_value_name: 'US12.5',
                attr_value_name_en: 'US12.5',
                attr_std_value: '',
              },
              {
                attr_value_id: 712,
                attr_value_name: 'US13',
                attr_value_name_en: 'US13',
                attr_std_value: '',
              },
              {
                attr_value_id: 1359,
                attr_value_name: 'US13.5',
                attr_value_name_en: 'US13.5',
                attr_std_value: '',
              },
              {
                attr_value_id: 1425732,
                attr_value_name: 'US14.5',
                attr_value_name_en: 'US14.5',
                attr_std_value: '',
              },
            ],
          },
        ],
      },
    },
    {
      relatedColor: '',
      cacheDataV6: '31555234@1',
      goods_id: 31555234,
      goods_sn: 'sk2403068141106626',
      goods_name: 'Girls Dress Shoes Slip-On Flower Girl Bow Ballet Flats With The Perfect Dress For Parties, Weddings, Shows, And More Special Occasions,Lightweight TPR Outsole (Little/Big Kids)',
      goods_url_name: 'Girls Dress Shoes Slip On Flower Girl Bow Ballet Flats With The Perfect Dress For Parties Weddings Shows And More Special Occasions Lightweight TPR Outsole Little Big Kids',
      cat_id: 2071,
      goods_img: '//img.ltwebstatic.com/images3_spmp/2024/03/15/5d/17104665266abf87c45318643b24e177283b9d2fef_thumbnail_405x552.jpg',
      is_on_sale: 1,
      original_img: '//img.ltwebstatic.com/images3_spmp/2024/03/15/5d/17104665266abf87c45318643b24e177283b9d2fef.jpg',
      goods_color_img: '//img.ltwebstatic.com/images3_spmp/2024/03/07/b3/17098052092e8537fe233805ade99eb2bf11c477dd.jpg',
      supplier_id: 2264411,
      productRelationID: 'k24030681411',
      goods_img_webp: '//img.ltwebstatic.com/images3_spmp/2024/03/15/5d/17104665266abf87c45318643b24e177283b9d2fef_thumbnail_405x552.webp',
      original_img_webp: '//img.ltwebstatic.com/images3_spmp/2024/03/15/5d/17104665266abf87c45318643b24e177283b9d2fef.webp',
      is_pre_sale_end: 0,
      business_model: 1,
      store_code: 6596207479,
      mall_code: '1',
      ext: {
        rec_mark: 'mmd_12498|bkt|rkt|loc_8_4',
      },
      mallList: [
        {
          mall_code: '1',
          stock: 999,
          mall_code_sort: 1,
          sku_quick_ship: true,
        },
      ],
      retailPrice: {
        amount: '40.99',
        amountWithSymbol: '$40.99',
        usdAmount: '40.99',
        usdAmountWithSymbol: '$40.99',
        amountSimpleText: '40.99',
      },
      salePrice: {
        amount: '19.99',
        amountWithSymbol: '$19.99',
        usdAmount: '19.99',
        usdAmountWithSymbol: '$19.99',
        amountSimpleText: '19.99',
      },
      promotionInfo: [
      ],
      appPromotion: [
      ],
      discountPrice: {
        amount: '21.00',
        amountWithSymbol: '$21.00',
        usdAmount: '21.00',
        usdAmountWithSymbol: '$21.00',
        amountSimpleText: '21.00',
      },
      unit_discount: 51,
      original_discount: '51',
      mall_price: [
        {
          mall_code: '1',
          retailPrice: {
            amount: '40.99',
            amountWithSymbol: '$40.99',
            usdAmount: '40.99',
            usdAmountWithSymbol: '$40.99',
            amountSimpleText: '40.99',
          },
          salePrice: {
            amount: '19.99',
            amountWithSymbol: '$19.99',
            usdAmount: '19.99',
            usdAmountWithSymbol: '$19.99',
            amountSimpleText: '19.99',
          },
          discountPrice: {
            amount: '21.00',
            amountWithSymbol: '$21.00',
            usdAmount: '21.00',
            usdAmountWithSymbol: '$21.00',
            amountSimpleText: '21.00',
          },
          unit_discount: 51,
          promotion_status: null,
          promotion_logo_type: null,
          original_discount: '51',
          coupon_prices: [
          ],
        },
      ],
      mallAppPromotion: [
      ],
      mallPromotionInfo: [
      ],
      promotion_status: null,
      promotion_logo_type: null,
      coupon_prices: [
      ],
      showPrice: null,
      showTypeId: null,
      vipDiscountPrice: null,
      suitRulePrice: null,
      rule_num: null,
      discountValue: null,
      isInsurable: 0,
      promotion_discount_info: [
      ],
      coupons: [
      ],
      skc: 'sk2403068141106626',
      brand: null,
      series: null,
      series_list: [
      ],
      _series: {
      },
      _brand: {
      },
      productDetails: [
      ],
      comment: {
        comment_num: '0',
        comment_rank: '0',
      },
      sale_attr_list: {
        goods_id: '31555234',
        goods_sn: 'sk2403068141106626',
        skc_name: 'sk2403068141106626',
        sku_list: [
          {
            sku_code: 'I435wl9fk0zp',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 709,
                attr_value_name: 'US1',
                attr_value_name_en: 'US1',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '40.99',
                amountWithSymbol: '$40.99',
                usdAmount: '40.99',
                usdAmountWithSymbol: '$40.99',
                amountSimpleText: '40.99',
              },
              salePrice: {
                amount: '19.99',
                amountWithSymbol: '$19.99',
                usdAmount: '19.99',
                usdAmountWithSymbol: '$19.99',
                amountSimpleText: '19.99',
              },
              discountPrice: {
                amount: '21.00',
                amountWithSymbol: '$21.00',
                usdAmount: '21.00',
                usdAmountWithSymbol: '$21.00',
                amountSimpleText: '21.00',
              },
              unit_discount: 51,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 19,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '40.99',
                  amountWithSymbol: '$40.99',
                  usdAmount: '40.99',
                  usdAmountWithSymbol: '$40.99',
                  amountSimpleText: '40.99',
                },
                salePrice: {
                  amount: '19.99',
                  amountWithSymbol: '$19.99',
                  usdAmount: '19.99',
                  usdAmountWithSymbol: '$19.99',
                  amountSimpleText: '19.99',
                },
                discountPrice: {
                  amount: '21.00',
                  amountWithSymbol: '$21.00',
                  usdAmount: '21.00',
                  usdAmountWithSymbol: '$21.00',
                  amountSimpleText: '21.00',
                },
                unit_discount: 51,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 19,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 19,
            doublePoints: 0,
            mallStock: 3,
          },
          {
            sku_code: 'I435wl9g81sm',
            stock: 999,
            mall_stock: [
              {
                mall_code: '1',
                stock: 999,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 1349,
                attr_value_name: 'US2',
                attr_value_name_en: 'US2',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '40.99',
                amountWithSymbol: '$40.99',
                usdAmount: '40.99',
                usdAmountWithSymbol: '$40.99',
                amountSimpleText: '40.99',
              },
              salePrice: {
                amount: '19.99',
                amountWithSymbol: '$19.99',
                usdAmount: '19.99',
                usdAmountWithSymbol: '$19.99',
                amountSimpleText: '19.99',
              },
              discountPrice: {
                amount: '21.00',
                amountWithSymbol: '$21.00',
                usdAmount: '21.00',
                usdAmountWithSymbol: '$21.00',
                amountSimpleText: '21.00',
              },
              unit_discount: 51,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 19,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '40.99',
                  amountWithSymbol: '$40.99',
                  usdAmount: '40.99',
                  usdAmountWithSymbol: '$40.99',
                  amountSimpleText: '40.99',
                },
                salePrice: {
                  amount: '19.99',
                  amountWithSymbol: '$19.99',
                  usdAmount: '19.99',
                  usdAmountWithSymbol: '$19.99',
                  amountSimpleText: '19.99',
                },
                discountPrice: {
                  amount: '21.00',
                  amountWithSymbol: '$21.00',
                  usdAmount: '21.00',
                  usdAmountWithSymbol: '$21.00',
                  amountSimpleText: '21.00',
                },
                unit_discount: 51,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 19,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 19,
            doublePoints: 0,
            mallStock: 999,
          },
          {
            sku_code: 'I435wl9ge95z',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 714,
                attr_value_name: 'US3',
                attr_value_name_en: 'US3',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '40.99',
                amountWithSymbol: '$40.99',
                usdAmount: '40.99',
                usdAmountWithSymbol: '$40.99',
                amountSimpleText: '40.99',
              },
              salePrice: {
                amount: '19.99',
                amountWithSymbol: '$19.99',
                usdAmount: '19.99',
                usdAmountWithSymbol: '$19.99',
                amountSimpleText: '19.99',
              },
              discountPrice: {
                amount: '21.00',
                amountWithSymbol: '$21.00',
                usdAmount: '21.00',
                usdAmountWithSymbol: '$21.00',
                amountSimpleText: '21.00',
              },
              unit_discount: 51,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 19,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '40.99',
                  amountWithSymbol: '$40.99',
                  usdAmount: '40.99',
                  usdAmountWithSymbol: '$40.99',
                  amountSimpleText: '40.99',
                },
                salePrice: {
                  amount: '19.99',
                  amountWithSymbol: '$19.99',
                  usdAmount: '19.99',
                  usdAmountWithSymbol: '$19.99',
                  amountSimpleText: '19.99',
                },
                discountPrice: {
                  amount: '21.00',
                  amountWithSymbol: '$21.00',
                  usdAmount: '21.00',
                  usdAmountWithSymbol: '$21.00',
                  amountSimpleText: '21.00',
                },
                unit_discount: 51,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 19,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 19,
            doublePoints: 0,
            mallStock: 3,
          },
          {
            sku_code: 'I435wl9gjvp6',
            stock: 999,
            mall_stock: [
              {
                mall_code: '1',
                stock: 999,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 1352,
                attr_value_name: 'US4',
                attr_value_name_en: 'US4',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '40.99',
                amountWithSymbol: '$40.99',
                usdAmount: '40.99',
                usdAmountWithSymbol: '$40.99',
                amountSimpleText: '40.99',
              },
              salePrice: {
                amount: '19.99',
                amountWithSymbol: '$19.99',
                usdAmount: '19.99',
                usdAmountWithSymbol: '$19.99',
                amountSimpleText: '19.99',
              },
              discountPrice: {
                amount: '21.00',
                amountWithSymbol: '$21.00',
                usdAmount: '21.00',
                usdAmountWithSymbol: '$21.00',
                amountSimpleText: '21.00',
              },
              unit_discount: 51,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 19,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '40.99',
                  amountWithSymbol: '$40.99',
                  usdAmount: '40.99',
                  usdAmountWithSymbol: '$40.99',
                  amountSimpleText: '40.99',
                },
                salePrice: {
                  amount: '19.99',
                  amountWithSymbol: '$19.99',
                  usdAmount: '19.99',
                  usdAmountWithSymbol: '$19.99',
                  amountSimpleText: '19.99',
                },
                discountPrice: {
                  amount: '21.00',
                  amountWithSymbol: '$21.00',
                  usdAmount: '21.00',
                  usdAmountWithSymbol: '$21.00',
                  amountSimpleText: '21.00',
                },
                unit_discount: 51,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 19,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 19,
            doublePoints: 0,
            mallStock: 999,
          },
          {
            sku_code: 'I435wl9gpmc9',
            stock: 999,
            mall_stock: [
              {
                mall_code: '1',
                stock: 999,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 715,
                attr_value_name: 'US5',
                attr_value_name_en: 'US5',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '40.99',
                amountWithSymbol: '$40.99',
                usdAmount: '40.99',
                usdAmountWithSymbol: '$40.99',
                amountSimpleText: '40.99',
              },
              salePrice: {
                amount: '19.99',
                amountWithSymbol: '$19.99',
                usdAmount: '19.99',
                usdAmountWithSymbol: '$19.99',
                amountSimpleText: '19.99',
              },
              discountPrice: {
                amount: '21.00',
                amountWithSymbol: '$21.00',
                usdAmount: '21.00',
                usdAmountWithSymbol: '$21.00',
                amountSimpleText: '21.00',
              },
              unit_discount: 51,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 19,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '40.99',
                  amountWithSymbol: '$40.99',
                  usdAmount: '40.99',
                  usdAmountWithSymbol: '$40.99',
                  amountSimpleText: '40.99',
                },
                salePrice: {
                  amount: '19.99',
                  amountWithSymbol: '$19.99',
                  usdAmount: '19.99',
                  usdAmountWithSymbol: '$19.99',
                  amountSimpleText: '19.99',
                },
                discountPrice: {
                  amount: '21.00',
                  amountWithSymbol: '$21.00',
                  usdAmount: '21.00',
                  usdAmountWithSymbol: '$21.00',
                  amountSimpleText: '21.00',
                },
                unit_discount: 51,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 19,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 19,
            doublePoints: 0,
            mallStock: 999,
          },
          {
            sku_code: 'I435wl9gvifm',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 717,
                attr_value_name: 'US6',
                attr_value_name_en: 'US6',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '40.99',
                amountWithSymbol: '$40.99',
                usdAmount: '40.99',
                usdAmountWithSymbol: '$40.99',
                amountSimpleText: '40.99',
              },
              salePrice: {
                amount: '19.99',
                amountWithSymbol: '$19.99',
                usdAmount: '19.99',
                usdAmountWithSymbol: '$19.99',
                amountSimpleText: '19.99',
              },
              discountPrice: {
                amount: '21.00',
                amountWithSymbol: '$21.00',
                usdAmount: '21.00',
                usdAmountWithSymbol: '$21.00',
                amountSimpleText: '21.00',
              },
              unit_discount: 51,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 19,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '40.99',
                  amountWithSymbol: '$40.99',
                  usdAmount: '40.99',
                  usdAmountWithSymbol: '$40.99',
                  amountSimpleText: '40.99',
                },
                salePrice: {
                  amount: '19.99',
                  amountWithSymbol: '$19.99',
                  usdAmount: '19.99',
                  usdAmountWithSymbol: '$19.99',
                  amountSimpleText: '19.99',
                },
                discountPrice: {
                  amount: '21.00',
                  amountWithSymbol: '$21.00',
                  usdAmount: '21.00',
                  usdAmountWithSymbol: '$21.00',
                  amountSimpleText: '21.00',
                },
                unit_discount: 51,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 19,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 19,
            doublePoints: 0,
            mallStock: 3,
          },
          {
            sku_code: 'I435wl9fovj0',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 711,
                attr_value_name: 'US11',
                attr_value_name_en: 'US11',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '40.99',
                amountWithSymbol: '$40.99',
                usdAmount: '40.99',
                usdAmountWithSymbol: '$40.99',
                amountSimpleText: '40.99',
              },
              salePrice: {
                amount: '19.99',
                amountWithSymbol: '$19.99',
                usdAmount: '19.99',
                usdAmountWithSymbol: '$19.99',
                amountSimpleText: '19.99',
              },
              discountPrice: {
                amount: '21.00',
                amountWithSymbol: '$21.00',
                usdAmount: '21.00',
                usdAmountWithSymbol: '$21.00',
                amountSimpleText: '21.00',
              },
              unit_discount: 51,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 19,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '40.99',
                  amountWithSymbol: '$40.99',
                  usdAmount: '40.99',
                  usdAmountWithSymbol: '$40.99',
                  amountSimpleText: '40.99',
                },
                salePrice: {
                  amount: '19.99',
                  amountWithSymbol: '$19.99',
                  usdAmount: '19.99',
                  usdAmountWithSymbol: '$19.99',
                  amountSimpleText: '19.99',
                },
                discountPrice: {
                  amount: '21.00',
                  amountWithSymbol: '$21.00',
                  usdAmount: '21.00',
                  usdAmountWithSymbol: '$21.00',
                  amountSimpleText: '21.00',
                },
                unit_discount: 51,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 19,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 19,
            doublePoints: 0,
            mallStock: 3,
          },
          {
            sku_code: 'I435wl9fwcws',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 1357,
                attr_value_name: 'US12',
                attr_value_name_en: 'US12',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '40.99',
                amountWithSymbol: '$40.99',
                usdAmount: '40.99',
                usdAmountWithSymbol: '$40.99',
                amountSimpleText: '40.99',
              },
              salePrice: {
                amount: '19.99',
                amountWithSymbol: '$19.99',
                usdAmount: '19.99',
                usdAmountWithSymbol: '$19.99',
                amountSimpleText: '19.99',
              },
              discountPrice: {
                amount: '21.00',
                amountWithSymbol: '$21.00',
                usdAmount: '21.00',
                usdAmountWithSymbol: '$21.00',
                amountSimpleText: '21.00',
              },
              unit_discount: 51,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 19,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '40.99',
                  amountWithSymbol: '$40.99',
                  usdAmount: '40.99',
                  usdAmountWithSymbol: '$40.99',
                  amountSimpleText: '40.99',
                },
                salePrice: {
                  amount: '19.99',
                  amountWithSymbol: '$19.99',
                  usdAmount: '19.99',
                  usdAmountWithSymbol: '$19.99',
                  amountSimpleText: '19.99',
                },
                discountPrice: {
                  amount: '21.00',
                  amountWithSymbol: '$21.00',
                  usdAmount: '21.00',
                  usdAmountWithSymbol: '$21.00',
                  amountSimpleText: '21.00',
                },
                unit_discount: 51,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 19,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 19,
            doublePoints: 0,
            mallStock: 3,
          },
          {
            sku_code: 'I435wl9g13zr',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 712,
                attr_value_name: 'US13',
                attr_value_name_en: 'US13',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '40.99',
                amountWithSymbol: '$40.99',
                usdAmount: '40.99',
                usdAmountWithSymbol: '$40.99',
                amountSimpleText: '40.99',
              },
              salePrice: {
                amount: '19.99',
                amountWithSymbol: '$19.99',
                usdAmount: '19.99',
                usdAmountWithSymbol: '$19.99',
                amountSimpleText: '19.99',
              },
              discountPrice: {
                amount: '21.00',
                amountWithSymbol: '$21.00',
                usdAmount: '21.00',
                usdAmountWithSymbol: '$21.00',
                amountSimpleText: '21.00',
              },
              unit_discount: 51,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 19,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '40.99',
                  amountWithSymbol: '$40.99',
                  usdAmount: '40.99',
                  usdAmountWithSymbol: '$40.99',
                  amountSimpleText: '40.99',
                },
                salePrice: {
                  amount: '19.99',
                  amountWithSymbol: '$19.99',
                  usdAmount: '19.99',
                  usdAmountWithSymbol: '$19.99',
                  amountSimpleText: '19.99',
                },
                discountPrice: {
                  amount: '21.00',
                  amountWithSymbol: '$21.00',
                  usdAmount: '21.00',
                  usdAmountWithSymbol: '$21.00',
                  amountSimpleText: '21.00',
                },
                unit_discount: 51,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 19,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 19,
            doublePoints: 0,
            mallStock: 3,
          },
        ],
        skc_sale_attr: [
          {
            attr_id: 87,
            attr_name: 'Size',
            attr_value_list: [
              {
                attr_value_id: 709,
                attr_value_name: 'US1',
                attr_value_name_en: 'US1',
                attr_std_value: '',
              },
              {
                attr_value_id: 1349,
                attr_value_name: 'US2',
                attr_value_name_en: 'US2',
                attr_std_value: '',
              },
              {
                attr_value_id: 714,
                attr_value_name: 'US3',
                attr_value_name_en: 'US3',
                attr_std_value: '',
              },
              {
                attr_value_id: 1352,
                attr_value_name: 'US4',
                attr_value_name_en: 'US4',
                attr_std_value: '',
              },
              {
                attr_value_id: 715,
                attr_value_name: 'US5',
                attr_value_name_en: 'US5',
                attr_std_value: '',
              },
              {
                attr_value_id: 717,
                attr_value_name: 'US6',
                attr_value_name_en: 'US6',
                attr_std_value: '',
              },
              {
                attr_value_id: 711,
                attr_value_name: 'US11',
                attr_value_name_en: 'US11',
                attr_std_value: '',
              },
              {
                attr_value_id: 1357,
                attr_value_name: 'US12',
                attr_value_name_en: 'US12',
                attr_std_value: '',
              },
              {
                attr_value_id: 712,
                attr_value_name: 'US13',
                attr_value_name_en: 'US13',
                attr_std_value: '',
              },
            ],
          },
        ],
      },
    },
    {
      relatedColor: '',
      cacheDataV6: '31549371@1',
      goods_id: 31549371,
      goods_sn: 'sk2402281757700530',
      goods_name: 'Little/Big Kid Boys Girls Shoes Kids Tennis Athletic Running Anti-Slip Walking Lightweight Sneakers ,Abrasion-Resistant Materials On The Front Toe Rubber Outsole',
      goods_url_name: 'Little Big Kid Boys Girls Shoes Kids Tennis Athletic Running Anti Slip Walking Lightweight Sneakers Abrasion Resistant Materials On The Front Toe Rubber Outsole',
      cat_id: 2072,
      goods_img: '//img.ltwebstatic.com/images3_spmp/2024/03/15/58/17104702248bc7525bfbbb9659b1b08d37f3226b02_thumbnail_405x552.jpg',
      is_on_sale: 1,
      original_img: '//img.ltwebstatic.com/images3_spmp/2024/03/15/58/17104702248bc7525bfbbb9659b1b08d37f3226b02.jpg',
      goods_color_img: '//img.ltwebstatic.com/images3_spmp/2024/03/15/5c/1710470567abf2693226e5eac3edbf2765f94d1760.jpg',
      supplier_id: 2264411,
      productRelationID: 'k24022817577',
      goods_img_webp: '//img.ltwebstatic.com/images3_spmp/2024/03/15/58/17104702248bc7525bfbbb9659b1b08d37f3226b02_thumbnail_405x552.webp',
      original_img_webp: '//img.ltwebstatic.com/images3_spmp/2024/03/15/58/17104702248bc7525bfbbb9659b1b08d37f3226b02.webp',
      is_pre_sale_end: 0,
      business_model: 1,
      store_code: 6596207479,
      mall_code: '1',
      ext: {
        rec_mark: 'mmd_12498|bkt|rkt|loc_9_5',
      },
      mallList: [
        {
          mall_code: '1',
          stock: 999,
          mall_code_sort: 1,
          sku_quick_ship: true,
        },
      ],
      retailPrice: {
        amount: '47.99',
        amountWithSymbol: '$47.99',
        usdAmount: '47.99',
        usdAmountWithSymbol: '$47.99',
        amountSimpleText: '47.99',
      },
      salePrice: {
        amount: '24.99',
        amountWithSymbol: '$24.99',
        usdAmount: '24.99',
        usdAmountWithSymbol: '$24.99',
        amountSimpleText: '24.99',
      },
      promotionInfo: [
      ],
      appPromotion: [
      ],
      discountPrice: {
        amount: '23.00',
        amountWithSymbol: '$23.00',
        usdAmount: '23.00',
        usdAmountWithSymbol: '$23.00',
        amountSimpleText: '23.00',
      },
      unit_discount: 48,
      original_discount: '48',
      mall_price: [
        {
          mall_code: '1',
          retailPrice: {
            amount: '47.99',
            amountWithSymbol: '$47.99',
            usdAmount: '47.99',
            usdAmountWithSymbol: '$47.99',
            amountSimpleText: '47.99',
          },
          salePrice: {
            amount: '24.99',
            amountWithSymbol: '$24.99',
            usdAmount: '24.99',
            usdAmountWithSymbol: '$24.99',
            amountSimpleText: '24.99',
          },
          discountPrice: {
            amount: '23.00',
            amountWithSymbol: '$23.00',
            usdAmount: '23.00',
            usdAmountWithSymbol: '$23.00',
            amountSimpleText: '23.00',
          },
          unit_discount: 48,
          promotion_status: null,
          promotion_logo_type: null,
          original_discount: '48',
          coupon_prices: [
          ],
        },
      ],
      mallAppPromotion: [
      ],
      mallPromotionInfo: [
      ],
      promotion_status: null,
      promotion_logo_type: null,
      coupon_prices: [
      ],
      showPrice: null,
      showTypeId: null,
      vipDiscountPrice: null,
      suitRulePrice: null,
      rule_num: null,
      discountValue: null,
      isInsurable: 0,
      promotion_discount_info: [
      ],
      coupons: [
      ],
      skc: 'sk2402281757700530',
      brand: null,
      series: null,
      series_list: [
      ],
      _series: {
      },
      _brand: {
      },
      productDetails: [
      ],
      comment: {
        comment_num: '0',
        comment_rank: '0',
      },
      sale_attr_list: {
        goods_id: '31549371',
        goods_sn: 'sk2402281757700530',
        skc_name: 'sk2402281757700530',
        sku_list: [
          {
            sku_code: 'I3179bi5pi2c',
            stock: 0,
            mall_stock: [
              {
                mall_code: '1',
                stock: 0,
                mall_code_sort: 1,
                sku_quick_ship: false,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 709,
                attr_value_name: 'US1',
                attr_value_name_en: 'US1',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '47.99',
                amountWithSymbol: '$47.99',
                usdAmount: '47.99',
                usdAmountWithSymbol: '$47.99',
                amountSimpleText: '47.99',
              },
              salePrice: {
                amount: '24.99',
                amountWithSymbol: '$24.99',
                usdAmount: '24.99',
                usdAmountWithSymbol: '$24.99',
                amountSimpleText: '24.99',
              },
              discountPrice: {
                amount: '23.00',
                amountWithSymbol: '$23.00',
                usdAmount: '23.00',
                usdAmountWithSymbol: '$23.00',
                amountSimpleText: '23.00',
              },
              unit_discount: 48,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 24,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '47.99',
                  amountWithSymbol: '$47.99',
                  usdAmount: '47.99',
                  usdAmountWithSymbol: '$47.99',
                  amountSimpleText: '47.99',
                },
                salePrice: {
                  amount: '24.99',
                  amountWithSymbol: '$24.99',
                  usdAmount: '24.99',
                  usdAmountWithSymbol: '$24.99',
                  amountSimpleText: '24.99',
                },
                discountPrice: {
                  amount: '23.00',
                  amountWithSymbol: '$23.00',
                  usdAmount: '23.00',
                  usdAmountWithSymbol: '$23.00',
                  amountSimpleText: '23.00',
                },
                unit_discount: 48,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 24,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 24,
            doublePoints: 0,
            mallStock: 0,
          },
          {
            sku_code: 'I3179bi5zfxd',
            stock: 1,
            mall_stock: [
              {
                mall_code: '1',
                stock: 1,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 1349,
                attr_value_name: 'US2',
                attr_value_name_en: 'US2',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '47.99',
                amountWithSymbol: '$47.99',
                usdAmount: '47.99',
                usdAmountWithSymbol: '$47.99',
                amountSimpleText: '47.99',
              },
              salePrice: {
                amount: '24.99',
                amountWithSymbol: '$24.99',
                usdAmount: '24.99',
                usdAmountWithSymbol: '$24.99',
                amountSimpleText: '24.99',
              },
              discountPrice: {
                amount: '23.00',
                amountWithSymbol: '$23.00',
                usdAmount: '23.00',
                usdAmountWithSymbol: '$23.00',
                amountSimpleText: '23.00',
              },
              unit_discount: 48,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 24,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '47.99',
                  amountWithSymbol: '$47.99',
                  usdAmount: '47.99',
                  usdAmountWithSymbol: '$47.99',
                  amountSimpleText: '47.99',
                },
                salePrice: {
                  amount: '24.99',
                  amountWithSymbol: '$24.99',
                  usdAmount: '24.99',
                  usdAmountWithSymbol: '$24.99',
                  amountSimpleText: '24.99',
                },
                discountPrice: {
                  amount: '23.00',
                  amountWithSymbol: '$23.00',
                  usdAmount: '23.00',
                  usdAmountWithSymbol: '$23.00',
                  amountSimpleText: '23.00',
                },
                unit_discount: 48,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 24,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 24,
            doublePoints: 0,
            mallStock: 1,
          },
          {
            sku_code: 'I3179bi61ic8',
            stock: 1,
            mall_stock: [
              {
                mall_code: '1',
                stock: 1,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 714,
                attr_value_name: 'US3',
                attr_value_name_en: 'US3',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '47.99',
                amountWithSymbol: '$47.99',
                usdAmount: '47.99',
                usdAmountWithSymbol: '$47.99',
                amountSimpleText: '47.99',
              },
              salePrice: {
                amount: '24.99',
                amountWithSymbol: '$24.99',
                usdAmount: '24.99',
                usdAmountWithSymbol: '$24.99',
                amountSimpleText: '24.99',
              },
              discountPrice: {
                amount: '23.00',
                amountWithSymbol: '$23.00',
                usdAmount: '23.00',
                usdAmountWithSymbol: '$23.00',
                amountSimpleText: '23.00',
              },
              unit_discount: 48,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 24,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '47.99',
                  amountWithSymbol: '$47.99',
                  usdAmount: '47.99',
                  usdAmountWithSymbol: '$47.99',
                  amountSimpleText: '47.99',
                },
                salePrice: {
                  amount: '24.99',
                  amountWithSymbol: '$24.99',
                  usdAmount: '24.99',
                  usdAmountWithSymbol: '$24.99',
                  amountSimpleText: '24.99',
                },
                discountPrice: {
                  amount: '23.00',
                  amountWithSymbol: '$23.00',
                  usdAmount: '23.00',
                  usdAmountWithSymbol: '$23.00',
                  amountSimpleText: '23.00',
                },
                unit_discount: 48,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 24,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 24,
            doublePoints: 0,
            mallStock: 1,
          },
          {
            sku_code: 'I3179bi63fs8',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 1352,
                attr_value_name: 'US4',
                attr_value_name_en: 'US4',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '47.99',
                amountWithSymbol: '$47.99',
                usdAmount: '47.99',
                usdAmountWithSymbol: '$47.99',
                amountSimpleText: '47.99',
              },
              salePrice: {
                amount: '24.99',
                amountWithSymbol: '$24.99',
                usdAmount: '24.99',
                usdAmountWithSymbol: '$24.99',
                amountSimpleText: '24.99',
              },
              discountPrice: {
                amount: '23.00',
                amountWithSymbol: '$23.00',
                usdAmount: '23.00',
                usdAmountWithSymbol: '$23.00',
                amountSimpleText: '23.00',
              },
              unit_discount: 48,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 24,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '47.99',
                  amountWithSymbol: '$47.99',
                  usdAmount: '47.99',
                  usdAmountWithSymbol: '$47.99',
                  amountSimpleText: '47.99',
                },
                salePrice: {
                  amount: '24.99',
                  amountWithSymbol: '$24.99',
                  usdAmount: '24.99',
                  usdAmountWithSymbol: '$24.99',
                  amountSimpleText: '24.99',
                },
                discountPrice: {
                  amount: '23.00',
                  amountWithSymbol: '$23.00',
                  usdAmount: '23.00',
                  usdAmountWithSymbol: '$23.00',
                  amountSimpleText: '23.00',
                },
                unit_discount: 48,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 24,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 24,
            doublePoints: 0,
            mallStock: 3,
          },
          {
            sku_code: 'I3179bi65c4c',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 715,
                attr_value_name: 'US5',
                attr_value_name_en: 'US5',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '47.99',
                amountWithSymbol: '$47.99',
                usdAmount: '47.99',
                usdAmountWithSymbol: '$47.99',
                amountSimpleText: '47.99',
              },
              salePrice: {
                amount: '24.99',
                amountWithSymbol: '$24.99',
                usdAmount: '24.99',
                usdAmountWithSymbol: '$24.99',
                amountSimpleText: '24.99',
              },
              discountPrice: {
                amount: '23.00',
                amountWithSymbol: '$23.00',
                usdAmount: '23.00',
                usdAmountWithSymbol: '$23.00',
                amountSimpleText: '23.00',
              },
              unit_discount: 48,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 24,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '47.99',
                  amountWithSymbol: '$47.99',
                  usdAmount: '47.99',
                  usdAmountWithSymbol: '$47.99',
                  amountSimpleText: '47.99',
                },
                salePrice: {
                  amount: '24.99',
                  amountWithSymbol: '$24.99',
                  usdAmount: '24.99',
                  usdAmountWithSymbol: '$24.99',
                  amountSimpleText: '24.99',
                },
                discountPrice: {
                  amount: '23.00',
                  amountWithSymbol: '$23.00',
                  usdAmount: '23.00',
                  usdAmountWithSymbol: '$23.00',
                  amountSimpleText: '23.00',
                },
                unit_discount: 48,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 24,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 24,
            doublePoints: 0,
            mallStock: 3,
          },
          {
            sku_code: 'I3179bi67gk6',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 717,
                attr_value_name: 'US6',
                attr_value_name_en: 'US6',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '47.99',
                amountWithSymbol: '$47.99',
                usdAmount: '47.99',
                usdAmountWithSymbol: '$47.99',
                amountSimpleText: '47.99',
              },
              salePrice: {
                amount: '24.99',
                amountWithSymbol: '$24.99',
                usdAmount: '24.99',
                usdAmountWithSymbol: '$24.99',
                amountSimpleText: '24.99',
              },
              discountPrice: {
                amount: '23.00',
                amountWithSymbol: '$23.00',
                usdAmount: '23.00',
                usdAmountWithSymbol: '$23.00',
                amountSimpleText: '23.00',
              },
              unit_discount: 48,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 24,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '47.99',
                  amountWithSymbol: '$47.99',
                  usdAmount: '47.99',
                  usdAmountWithSymbol: '$47.99',
                  amountSimpleText: '47.99',
                },
                salePrice: {
                  amount: '24.99',
                  amountWithSymbol: '$24.99',
                  usdAmount: '24.99',
                  usdAmountWithSymbol: '$24.99',
                  amountSimpleText: '24.99',
                },
                discountPrice: {
                  amount: '23.00',
                  amountWithSymbol: '$23.00',
                  usdAmount: '23.00',
                  usdAmountWithSymbol: '$23.00',
                  amountSimpleText: '23.00',
                },
                unit_discount: 48,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 24,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 24,
            doublePoints: 0,
            mallStock: 3,
          },
          {
            sku_code: 'I3179bi5rj8h',
            stock: 1,
            mall_stock: [
              {
                mall_code: '1',
                stock: 1,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 710,
                attr_value_name: 'US10',
                attr_value_name_en: 'US10',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '47.99',
                amountWithSymbol: '$47.99',
                usdAmount: '47.99',
                usdAmountWithSymbol: '$47.99',
                amountSimpleText: '47.99',
              },
              salePrice: {
                amount: '24.99',
                amountWithSymbol: '$24.99',
                usdAmount: '24.99',
                usdAmountWithSymbol: '$24.99',
                amountSimpleText: '24.99',
              },
              discountPrice: {
                amount: '23.00',
                amountWithSymbol: '$23.00',
                usdAmount: '23.00',
                usdAmountWithSymbol: '$23.00',
                amountSimpleText: '23.00',
              },
              unit_discount: 48,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 24,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '47.99',
                  amountWithSymbol: '$47.99',
                  usdAmount: '47.99',
                  usdAmountWithSymbol: '$47.99',
                  amountSimpleText: '47.99',
                },
                salePrice: {
                  amount: '24.99',
                  amountWithSymbol: '$24.99',
                  usdAmount: '24.99',
                  usdAmountWithSymbol: '$24.99',
                  amountSimpleText: '24.99',
                },
                discountPrice: {
                  amount: '23.00',
                  amountWithSymbol: '$23.00',
                  usdAmount: '23.00',
                  usdAmountWithSymbol: '$23.00',
                  amountSimpleText: '23.00',
                },
                unit_discount: 48,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 24,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 24,
            doublePoints: 0,
            mallStock: 1,
          },
          {
            sku_code: 'I3179bi5tefs',
            stock: 1,
            mall_stock: [
              {
                mall_code: '1',
                stock: 1,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 711,
                attr_value_name: 'US11',
                attr_value_name_en: 'US11',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '47.99',
                amountWithSymbol: '$47.99',
                usdAmount: '47.99',
                usdAmountWithSymbol: '$47.99',
                amountSimpleText: '47.99',
              },
              salePrice: {
                amount: '24.99',
                amountWithSymbol: '$24.99',
                usdAmount: '24.99',
                usdAmountWithSymbol: '$24.99',
                amountSimpleText: '24.99',
              },
              discountPrice: {
                amount: '23.00',
                amountWithSymbol: '$23.00',
                usdAmount: '23.00',
                usdAmountWithSymbol: '$23.00',
                amountSimpleText: '23.00',
              },
              unit_discount: 48,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 24,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '47.99',
                  amountWithSymbol: '$47.99',
                  usdAmount: '47.99',
                  usdAmountWithSymbol: '$47.99',
                  amountSimpleText: '47.99',
                },
                salePrice: {
                  amount: '24.99',
                  amountWithSymbol: '$24.99',
                  usdAmount: '24.99',
                  usdAmountWithSymbol: '$24.99',
                  amountSimpleText: '24.99',
                },
                discountPrice: {
                  amount: '23.00',
                  amountWithSymbol: '$23.00',
                  usdAmount: '23.00',
                  usdAmountWithSymbol: '$23.00',
                  amountSimpleText: '23.00',
                },
                unit_discount: 48,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 24,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 24,
            doublePoints: 0,
            mallStock: 1,
          },
          {
            sku_code: 'I3179bi5vacw',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 1357,
                attr_value_name: 'US12',
                attr_value_name_en: 'US12',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '47.99',
                amountWithSymbol: '$47.99',
                usdAmount: '47.99',
                usdAmountWithSymbol: '$47.99',
                amountSimpleText: '47.99',
              },
              salePrice: {
                amount: '24.99',
                amountWithSymbol: '$24.99',
                usdAmount: '24.99',
                usdAmountWithSymbol: '$24.99',
                amountSimpleText: '24.99',
              },
              discountPrice: {
                amount: '23.00',
                amountWithSymbol: '$23.00',
                usdAmount: '23.00',
                usdAmountWithSymbol: '$23.00',
                amountSimpleText: '23.00',
              },
              unit_discount: 48,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 24,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '47.99',
                  amountWithSymbol: '$47.99',
                  usdAmount: '47.99',
                  usdAmountWithSymbol: '$47.99',
                  amountSimpleText: '47.99',
                },
                salePrice: {
                  amount: '24.99',
                  amountWithSymbol: '$24.99',
                  usdAmount: '24.99',
                  usdAmountWithSymbol: '$24.99',
                  amountSimpleText: '24.99',
                },
                discountPrice: {
                  amount: '23.00',
                  amountWithSymbol: '$23.00',
                  usdAmount: '23.00',
                  usdAmountWithSymbol: '$23.00',
                  amountSimpleText: '23.00',
                },
                unit_discount: 48,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 24,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 24,
            doublePoints: 0,
            mallStock: 3,
          },
          {
            sku_code: 'I3179bi5xdcu',
            stock: 3,
            mall_stock: [
              {
                mall_code: '1',
                stock: 3,
                mall_code_sort: 1,
                sku_quick_ship: true,
              },
            ],
            sku_sale_attr: [
              {
                attr_id: 87,
                attr_name: 'Size',
                attr_name_en: 'Size',
                attr_value_id: 712,
                attr_value_name: 'US13',
                attr_value_name_en: 'US13',
                attr_std_value: '',
              },
            ],
            price: {
              mall_code: '1',
              retailPrice: {
                amount: '47.99',
                amountWithSymbol: '$47.99',
                usdAmount: '47.99',
                usdAmountWithSymbol: '$47.99',
                amountSimpleText: '47.99',
              },
              salePrice: {
                amount: '24.99',
                amountWithSymbol: '$24.99',
                usdAmount: '24.99',
                usdAmountWithSymbol: '$24.99',
                amountSimpleText: '24.99',
              },
              discountPrice: {
                amount: '23.00',
                amountWithSymbol: '$23.00',
                usdAmount: '23.00',
                usdAmountWithSymbol: '$23.00',
                amountSimpleText: '23.00',
              },
              unit_discount: 48,
              promotion_status: null,
              promotion_logo_type: null,
              coupon_prices: [
              ],
              rewardPoints: 24,
              showPrice: null,
              showTypeId: null,
              vipDiscountPrice: null,
              discountValue: null,
              promotion_discount_info: null,
              coupons: [
              ],
            },
            mall_price: [
              {
                mall_code: '1',
                retailPrice: {
                  amount: '47.99',
                  amountWithSymbol: '$47.99',
                  usdAmount: '47.99',
                  usdAmountWithSymbol: '$47.99',
                  amountSimpleText: '47.99',
                },
                salePrice: {
                  amount: '24.99',
                  amountWithSymbol: '$24.99',
                  usdAmount: '24.99',
                  usdAmountWithSymbol: '$24.99',
                  amountSimpleText: '24.99',
                },
                discountPrice: {
                  amount: '23.00',
                  amountWithSymbol: '$23.00',
                  usdAmount: '23.00',
                  usdAmountWithSymbol: '$23.00',
                  amountSimpleText: '23.00',
                },
                unit_discount: 48,
                promotion_status: null,
                promotion_logo_type: null,
                coupon_prices: [
                ],
                rewardPoints: 24,
                showPrice: null,
                showTypeId: null,
                vipDiscountPrice: null,
                discountValue: null,
                promotion_discount_info: null,
                coupons: [
                ],
              },
            ],
            rewardPoints: 24,
            doublePoints: 0,
            mallStock: 3,
          },
        ],
        skc_sale_attr: [
          {
            attr_id: 87,
            attr_name: 'Size',
            attr_value_list: [
              {
                attr_value_id: 709,
                attr_value_name: 'US1',
                attr_value_name_en: 'US1',
                attr_std_value: '',
              },
              {
                attr_value_id: 1349,
                attr_value_name: 'US2',
                attr_value_name_en: 'US2',
                attr_std_value: '',
              },
              {
                attr_value_id: 714,
                attr_value_name: 'US3',
                attr_value_name_en: 'US3',
                attr_std_value: '',
              },
              {
                attr_value_id: 1352,
                attr_value_name: 'US4',
                attr_value_name_en: 'US4',
                attr_std_value: '',
              },
              {
                attr_value_id: 715,
                attr_value_name: 'US5',
                attr_value_name_en: 'US5',
                attr_std_value: '',
              },
              {
                attr_value_id: 717,
                attr_value_name: 'US6',
                attr_value_name_en: 'US6',
                attr_std_value: '',
              },
              {
                attr_value_id: 710,
                attr_value_name: 'US10',
                attr_value_name_en: 'US10',
                attr_std_value: '',
              },
              {
                attr_value_id: 711,
                attr_value_name: 'US11',
                attr_value_name_en: 'US11',
                attr_std_value: '',
              },
              {
                attr_value_id: 1357,
                attr_value_name: 'US12',
                attr_value_name_en: 'US12',
                attr_std_value: '',
              },
              {
                attr_value_id: 712,
                attr_value_name: 'US13',
                attr_value_name_en: 'US13',
                attr_std_value: '',
              },
            ],
          },
        ],
      },
    },
  ],
}
