<template>
  <!-- S 列表有结果 -->
  <div
    v-if="styleType === 'list' && hasFeedBackData"
    ref="feed-back"
    class="feed-back feed-back_list"
  >
    {{ feedBackText }}
    <a
      :href="skipSrc"
      da-event-click="1-23-1-1"
      class="feed-back__link"
    >{{
      language.SHEIN_KEY_PC_17149
    }}</a>
  </div>
  <!-- E 列表有结果 -->

  <!-- S 列表无结果 -->
  <div
    v-else-if="styleType === 'empty' && hasFeedBackData"
    ref="feed-back"
    class="feed-back feed-back_empty"
  >
    <a
      :href="skipSrc"
      da-event-click="1-23-1-2"
      class="feed-back__link"
    >{{ language.SHEIN_KEY_PC_17149 }} &gt;</a>
  </div>
  <!-- E 列表无结果 -->
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'

daEventCenter.addSubscriber({ modulecode: '1-23-1' })

export default {
  name: 'FeedBack',
  props: {
    styleType: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState(['language']),
    ...mapGetters(['cccListOperation']),
    feedBackText() {
      return (
        this.cccListOperation?.pc_feedback_entry?.content?.[0]?.content?.props
          ?.items?.[0]?.text || ''
      )
    },
    feedBackTarget() {
      return (
        this.cccListOperation?.pc_feedback_entry?.content?.[0]?.content?.props
          ?.items?.[0]?.hrefTarget || ''
      )
    },
    // 跳转地址
    skipSrc() {
      return `/survey/${this.feedBackTarget}?type=search`
    },
    hasFeedBackData() {
      return this.feedBackText && this.feedBackTarget
    },
  },
  inject: ['scrollExpose'],
  mounted() {
    this.scrollExpose.subscribe({
      dom: this.$refs['feed-back'],
      callback: () => {
        daEventCenter.triggerNotice({
          daId: '1-23-1-3',
          extraData: {
            resultCount: this.styleType === 'list' ? 1 : 0,
          },
        })
      },
    })
  },
}
</script>

<style lang="less">
.product-list-v2 {
  .feed-back_list {
    position: relative;
    &.with-pagination {
      margin: -18px 0 60px 0;
      width: calc(100% - 400px);
    }
    &.text-center {
      width: 100%;
      text-align: center;
    }
    &.under-pagination {
      margin: 10px;
    }
    margin: 0 0 60px 0;
    width: calc(100% - 370px);
    color: #222;
    font-size: 16px;
    .feed-back__link {
      .margin-l(5px);
      color: #003569;
      font-weight: 700;
    }
  }
  .feed-back_empty {
    max-width: 400px;
    .feed-back__link {
      color: #003569;
      font-size: 12px;
    }
  }
}
</style>
