<template>
  <div
    v-if="searchCCCCard.wordType != 6"
    class="search-banner__container"
    :class="{
      'search-banner__view-new': isViewNew,
    }"
  >
    <div
      id="search-banner"
      class="search-banner"
      :class="{
        'search-banner_higher': !isServiceType,
      }"
      @click="() => isViewNew && handleJump()"
    >
      <img
        v-if="searchCCCCard.entranceImage"
        :class="{
          'search-banner__left-wider': !isServiceType,
        }"
        class="search-banner__left lazyload"
        :data-src="transformImg({ img: searchCCCCard.entranceImage })"
      />
      <div class="search-banner__middle">
        <div
          class="search-banner__middle-title"
          :class="{
            'search-banner__middle-title_gap': isFlashSaleType,
          }"
        >
          {{ searchCCCCard.title }}
        </div>
        <div
          v-if="isFlashSaleType"
          class="search-banner__middle-countdown"
        >
          <span class="middle-countdown__status">{{
            language.SHEIN_KEY_PC_14585.replace(' {0}', '')
          }}</span>
          <span class="middle-countdown__interval">{{ timeObj.H }}</span>
          <span class="middle-countdown__colon">:</span>
          <span class="middle-countdown__interval">{{ timeObj.M }}</span>
          <span class="middle-countdown__colon">:</span>
          <span class="middle-countdown__interval">{{ timeObj.S }}</span>
        </div>
        <div
          v-else
          class="search-banner__middle-desc"
        >
          {{ searchCCCCard.text }}
        </div>
      </div>
      <div
        da-event-click="1-4-1-15"
        da-event-expose="1-4-1-16"
        :data-poskey="poskey"
        :data-word-type="searchCCCCard.wordType"
        class="search-banner__right"
        @click="handleJump"
      >
        {{ language.SHEIN_KEY_PC_21650 }}
      </div>
    </div>
  </div>
  <div
    v-else
    class="banner-inner_full-warp"
    da-event-click="1-5-1-24"
    da-event-expose="1-5-1-25"
    :data-poskey="poskey"
    :data-word-type="6"
    @click="handleJump"
  >
    <img
      :src="searchCCCCard.entranceImage"
      class="banner-inner_full-img lazyload"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// import { getCccCateInfo } from 'public/src/pages/common/ccc_public_v2'
import { daEventCenter } from 'public/src/services/eventCenter'
import { CountDown } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { mergeQueryString } from '@shein/common-function'
import  { CccLink } from 'public/src/pages/components/ccc/common/ccc-link.js'

const daEventExpose = daEventCenter.getExposeInstance()

export default {
  name: 'SearchCCCCard',
  inject: ['transformImg'],
  props: {
    isViewNew: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cccLink: '',
      timeObj: {
        H: '00',
        M: '00',
        S: '00',
      },
      cccPublic: {},
      poskey: 'SearchCard',
    }
  },
  computed: {
    ...mapState(['language']),
    ...mapGetters(['searchCCCCard', 'catInfo']),
    isServiceType() {
      return this.searchCCCCard.wordType === 0
    },
    isFlashSaleType() {
      return this.searchCCCCard.wordType === 3
    },
  },
  beforeMount() {
    this.initCccPublic()
  },
  async mounted () {
    this.initCountDown()
    this.handleExpose()
    this.getLink()
  },
  methods: {
    handleJump() {
      if (!this.cccLink || this.cccLink?.indexOf('javascript:;') > -1) return
      const { src_module, src_tab_page_id, src_identifier } = this.catInfo
      const cccLink = mergeQueryString({
        url: this.cccLink,
        mergeObj: { src_module, src_tab_page_id, src_identifier },
      })
      window.open(cccLink, '_self')
      // window.open(cccLink, '_blank')
    },
    initCccPublic() {
      this.cccPublic = new CccLink({ 
        sceneData: {}, 
        propData: {}
      })
    },
    async getLink() {
      const { cateLinks, hrefType, hrefTarget, relativeUrl, adp, isInternalLink, mallCodes, categoryIds, additionalConfig } = this.searchCCCCard
      let url = ''
      url = this.cccPublic.getFullLink({
        item: {
          hrefType,
          hrefTarget,
          relativeUrl,
          adp,
          isInternalLink,
          mallCodes,
          categoryIds,
          additionalConfig
        },
        cateLinks,
      })
      this.cccLink = url
    },
    async initCountDown() {
      if (!this.isFlashSaleType) return
      const that = this
      const countTimer = new CountDown({
        countFunc(timeObj) {
          that.timeObj.S = timeObj.S
          that.timeObj.M = timeObj.M
          that.timeObj.H = timeObj.H
        }
      })
      const { zzz: startTime } = await schttp({ url: '/api/common/servicesTime/get' })
      const endTime = this.searchCCCCard.flashSaleEndTime
      countTimer.start({ seconds: endTime - startTime })
    },
    handleExpose() {
      daEventExpose.subscribe({
        keycode: `productListExposeCode\`1-4-1-16`,
      })
    },
  },
}
</script>

<style lang="less">
.search-banner {
  .flexbox();
  .align-center();
  .space-between();
  width: 65%;
  min-width: 480px;
  height: 64px;
  background: #fff;
  margin-bottom: 16px;
  padding: 8px 16px 8px 8px;
  &_higher {
    height: 80px;
  }
  &__left {
    width: 48px;
    min-width: 48px;
    max-width: 48px;
    height: 48px;
    margin-right: 16px;
  }
  &__left-wider {
    width: 64px;
    min-width: 64px;
    max-width: 64px;
    height: 64px;
    margin-left: -0.1067rem;
  }
  &__middle {
    flex: 1 1 auto;
    padding: 7px 40px 6px 0;
  }
  &__middle-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;
    &_gap {
      margin-bottom: 10px;
    }
  }
  &__middle-desc {
    .clamp(2);
    font-size: 12px;
    color: #767676;
    line-height: 14px;
  }
  &__middle-countdown {
    .flexbox();
    .align-center();
  }
  .middle-countdown {
    &__status {
      margin-right: 9px;
    }
    &__interval {
      width: auto;
      height: 20px;
      background: #222;
      color: #fff;
      line-height: 20px;
      text-align: center;
      padding: 0 3px;
    }
    &__colon {
      margin: 0 4px;
    }
  }
  &__right {
    padding: 0 10px;
    height: 28px;
    line-height: 28px;
    color: #fff;
    background: #222;
    cursor: pointer;
    /* rw:begin */
    background: #ff696e;
    border-radius: 4px;
    &:hover {
      color: #fff;
    }
  }
}

.search-banner__view-new {
    display: inline-block;
    cursor: pointer;
    margin-bottom: 16px;

    .search-banner {
      width: auto;
      background: #f6f6f6;
      margin-bottom: 0;
    }
    
    .search-banner__middle-desc {
      .clamp(1);
    }
  }
.banner-inner_full-warp {
  overflow: hidden;
  margin: -12px 0 12px 0;
  .banner-inner_full-img {
    cursor: pointer;
    width: 100%;
  }
}
</style>
