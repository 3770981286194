const actions = {
  dealingNewData ({ commit }, payload) {
    commit('dealingFilterAttr', payload)
    commit('dealingSelectedFilter', payload)
    commit('dealingResults', payload)
    commit('dealingGoods', payload)
  },   
}

export default actions
