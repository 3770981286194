<template>
  <div
    v-if="selectionBannerData && selectionBannerData.length"
    class="selection-banner"
    :class="selectionBannerClassName"
  >
    <div
      v-for="(contentItem, contentIndex) in selectionBannerData"
      ref="selectionBannerContainer"
      :key="contentIndex"
      class="selection-banner__box j-da-event-box"
    >
      <div
        v-for="(item, index) in contentItem.content.props.items"
        :key="index"
        class="selection-banner__item"
      >
        <a
          :style="{ paddingTop: getPlaceholderHeight(item.width, item.height) }"
          :href="
            cccPublic.length &&
              cccPublic[contentIndex].getCccLink({
                item,
                cccCateInfo,
                gaIndex: index,
                scIndex: index,
              })
          "
          tabindex="0"
          :aria-label="
            item.currentLanguageName ||
              item.defaultLanguageName ||
              item.hrefTypeTag
          "
          class="selection-banner__item-link j-analysis-link"
          role="link"
        >
          <img
            :data-src="item.imgSrc"
            :data-design-width="imgDesignWdith(contentItem.content.props.items.length)"
            :src="EmptyImg"
            class="selection-banner__item-img lazyload"
          />
        </a>
        <div
          v-for="(linkItem, linkIndex) in item.links"
          :key="linkIndex"
          class="selection-banner__item-list"
        >
          <h5 class="selection-banner__item-list-title">
            {{ linkItem.title }}
          </h5>
          <a
            v-for="(subLinkItem, subLinkIndex) in linkItem.subLinks"
            :key="subLinkIndex"
            :href="
              cccPublic.length &&
                cccPublic[contentIndex].getCccLink({
                  item,
                  cccCateInfo,
                  gaIndex: subLinkIndex,
                  scIndex: subLinkIndex,
                })
            "
            class="selection-banner__item-list-link"
          >
            <i
              v-if="GB_cssRight"
              class="svgicon svgicon-arrownew-left"
            ></i>
            {{ subLinkItem.title }}
            <i
              v-if="!GB_cssRight"
              class="svgicon svgicon-arrownew-left"
            ></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'public/src/icon/arrownew-left.svg'
import { mapState, mapGetters } from 'vuex'
import { getPlaceholderHeight } from '../utils/index'
import { CccInit, getCccCateInfo } from 'public/src/pages/common/ccc_public_v2'

import { daEventCenter } from 'public/src/services/eventCenter'
import { CccAnalysis } from 'public/src/pages/common/analysis/auto_ccc_analysis.js'
import { EmptyImg } from 'public/src/services/resource/index'

export default {
  name: 'SelectionBanner',

  props: {
    /**
     * 选品 Banner 的 blockName
     * 2: 底部 banner
     * 3: 筛选栏 banner
     */
    bannerBlockName: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      selectionBannerData: [],
      cccCateInfo: {},
      cccPublic: [],
      cccData: {},
      EmptyImg
    }
  },

  computed: {
    ...mapState([
      'LAZY_IMG',
      'LAZY_IMG_SQUARE_BIG',
      'GB_cssRight',
      'tagId',
      'IS_RW',
    ]),
    ...mapGetters(['catInfo', 'cccListOperation', 'listAbtResult']),
    /**
     * 底部 banner 和筛选栏 banner 样式略有区别
     */
    selectionBannerClassName() {
      const classNameMap = new Map([
        [2, 'selection-banner_bottom'],
        [3, 'selection-banner_side'],
      ])

      return classNameMap.get(this.bannerBlockName) || ''
    }
  },

  inject: ['transformImg', 'scrollExpose'],

  beforeMount() {
    if (this.catInfo.type === 'picks' && this.bannerBlockName === 0) return

    this.getSelectionBannerData()
  },

  mounted() {
    if (!this.selectionBannerData.length) return

    getCccCateInfo({
      cccData: [{ operations: this.selectionBannerData }],
    }).then((res) => {
      this.cccCateInfo = res
    })

    // 处理埋点
    this.handleSelectionBannerAnalysis()
  },

  methods: {
    getSelectionBannerData() {
      let result = []
      let abtGroups = [this.listAbtResult.Aod]

      if (this.bannerBlockName === 2) {
        this.cccData = this.cccListOperation?.pc_list_bottombanner || {}
        // abtGroups.push(this.listAbtResult.CccListBottombanner)
      } else if (this.bannerBlockName === 3) {
        this.cccData = this.cccListOperation?.pc_list_sidebanner || {}
        // abtGroups.push(this.listAbtResult.CccListSideBanner)
      }

      for (const contentItem of this.cccData?.content || []) {
        const items = contentItem?.content?.props?.items || []

        if (items.length) {
          result.push(contentItem)
        }

        // CCC 跳转初始化
        this.cccPublic.push(
          new CccInit({
            context: this.cccData,
            propData: contentItem,
            index: 1,
            sceneName: this.cccData.scene_name,
            tagId: this.tagId,
            abtGroups,
          })
        )
      }

      this.selectionBannerData = result
    },
    /**
     * banner埋点设置与曝光
     */
    handleSelectionBannerAnalysis() {
      this.$nextTick(() => {
        //  * 2: 底部 banner
        //  * 3: 筛选栏 banner
        const bannerPoskey = []
        bannerPoskey.push(this.listAbtResult.Aod?.poskey?.slice(3) || '')
        this.$refs['selectionBannerContainer'].forEach((bannerBox, index) => {
          // 1. 设置banner属性
          const cccAnalysis = new CccAnalysis(bannerBox, {
            index: 1,
            poskey: bannerPoskey.join(),
            saName: this.cccPublic[index]?.saName,
            scName: this.cccPublic[index]?.scName,
          })
          cccAnalysis.setDomsAttribute({
            propData: this.selectionBannerData[index],
            sceneName: this.cccData.scene_name,
          })
          //2. banner曝光
          this.scrollExpose.subscribe({
            dom: bannerBox,
            callback: ({ container }) => {
              // console.log(container)
              daEventCenter.triggerNotice({
                daId: '2-10-2',
                target: [
                  ...container.querySelectorAll('[da-event-click="2-10-1"]'),
                ],
              })
            },
          })
        })
      })
    },
    imgDesignWdith(count) {
      if (!count) {
        return 0
      }
      const containerWidth = this.bannerBlockName == 2 ? 1650 : 180
      return containerWidth / count
    },
    getPlaceholderHeight,
  },
}
</script>

<style lang="less">
.product-list-v2 {
  .selection-banner {
    &__box {
      position: relative;
      display: flex;
    }
    &__item {
      position: relative;
      flex: 1;
    }
    &__item-link {
      position: relative;
      display: block;
    }
    &__item-img {
      position: absolute;
      top: 0;
      left: 0 /*rtl: ignore*/;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &__item-list {
      position: absolute;
      top: 50%;
      .left(30px);
      transform: translateY(-50%);
      font-family: 'Muli';
    }
    &__item-list-title {
      margin: 10px 0 20px 0;
    }
    &__item-list-link {
      display: block;
      width: fit-content;
      margin: 10px 0;
      color: #666;
      text-decoration: none;
    }
  }
  .selection-banner_bottom {
    /* banner 位于底部时的样式 */
    .selection-banner__box {
      margin: 20px 0;
    }
    .selection-banner__item {
      /* stylelint-disable-next-line selector-max-specificity */
      &:not(:last-child) {
        .margin-r(20px);
      }
    }
  }
  .selection-banner_side {
    /* banner 位于侧边筛选栏时的样式 */
    .selection-banner__box {
      /* stylelint-disable-next-line selector-max-specificity */
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
