<template>
  <div 
    v-if="showCouponTip"
    class="coupon-tip-ctn"
  >
    <i class="svgicon svgicon-alert-notice"></i>{{ template(catInfo.CouponCode, language.SHEIN_KEY_PC_17695) }}
    <i
      da-event-click="1-4-1-14"
      class="svgicon close svgicon-biaoqianclose"
      @click="closeTip"
    >
    </i>
  </div>
</template>

<script>
import 'public/src/icon/alert-notice.svg'
import 'public/src/icon/biaoqianclose.svg'
import { mapGetters, mapState } from 'vuex'
import { template } from '@shein/common-function'
export default {
  data() {
    return {
      template,
      show: true,
      closeCouponTip: 1,
    }
  },
  computed: {
    ...mapState(['language', 'IS_RW']),
    ...mapGetters(['catInfo', 'listAbtResult']),
    showCouponTip() {
      return (
        this.catInfo?.ShowTip == '1' &&
        this.show &&
        !this.closeCouponTip
      )
    },
  },
  mounted() {
    this.initTipCloseStatus()
  },
  methods: {
    closeTip() {
      sessionStorage.setItem('closeCouponTip', '1')
      this.show = false
    },
    initTipCloseStatus() {
      if (!sessionStorage.closeCouponTip) {
        this.closeCouponTip = false
      }
    },
  },
}
</script>

<style lang="less">
.product-list-v2 {
  .coupon-tip-ctn {
    line-height: 20px;
    padding: 20px 0 20px 24px /*rtl:ignore*/;
    background-color: #ebf4ff;
    margin: 16px 0;
    font-size: 14px;
    color: #222;
    .close {
        cursor: pointer;
        color: #999;
        .fr();
    }
    .svgicon {
      width: 16px;
      height: 16px;
      color: #666;
      .margin-r(12px);
    }
    .close {
      cursor: pointer;
      color: #999;
      .fr();
    }
  }
}
</style>
