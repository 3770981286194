import { createSSRApp as createVueApp } from 'vue'
import { registerBaseContext } from '../common/common_inject'
import App from './App.vue'

import { productListStore } from './store/index'

export function createApp(context) {
  const store = productListStore()

  const app = createVueApp(App, {
    context
  })
  registerBaseContext(app, context)

  app.use(store)
  
  return { app, store }
}
