import { createApp } from './app'
import infiniteScroll from '@sheinfe/vue-infinite-scroll'
import { businessLibInitializeForCSR } from 'public/src/pages/common/business-lib-initialize/csr.js'

const { app, store } = createApp(window.gbRawData)

if (window.gbRawData) {
  if (window.gbRawData.headerVueSsr) {
    businessLibInitializeForCSR(app)
  }
  store.replaceState({ ...store.state, ...window.gbRawData })
}
app.use(infiniteScroll)
app.mount('#product-list-v2') // vue3会多加一层div，  不会替换div

