<template>
  <div class="service-type-tip">
    {{ language.SHEIN_KEY_PC_21253 }}
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ServiceTypeTips',
  computed: {
    ...mapState(['language']),
  },
}
</script>

<style lang="less">
.service-type-tip {
  color: #767676;
  font-size: 12px;
  margin-top: 16px;
}
</style>
