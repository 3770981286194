<template>
  <!-- S Top Banner -->
  <div
    class="picks-banner"
  >
    <div class="picks-banner__container">
      <div
        v-for="(item, index) in bannerData"
        :key="index"
        class="picks-banner__item"
      >
        <div
          class="picks-banner__item-ph"
          :style="{ paddingTop: getPlaceholderHeight(item.width, item.height) }"
        >
          <img
            :src="transformImg({ img: item.b_img || item.imgSrc })"
            class="picks-banner__item-img"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- E Top Banner -->
</template>

<script>
import { mapGetters } from 'vuex'
import { getPlaceholderHeight } from '../utils/index'

export default {
  name: 'PicksBanner',
  props: {
    bannerData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(['catInfo', 'topBanner']),
  },
  inject: ['transformImg'],
  methods: {
    getPlaceholderHeight,
  },
}
</script>

<style lang="less">
.product-list-v2 {
  .picks-banner {
    &__title {
      margin-bottom: 40px;
      text-transform: capitalize;
      text-align: center;
    }
    &__container {
      position: relative;
      display: flex;
      margin-bottom: 15px;
    }
    &__item {
      position: relative;
      flex: 1;
      &:not(:last-child) {
        .margin-r(20px);
      }
    }
    &__item-ph,
    &__item-link {
      position: relative;
      display: block;
    }
    &__item-img {
      position: absolute;
      top: 0;
      left: 0 /*rtl:ignore*/;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
